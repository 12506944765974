.dateSearch {
  position: relative;
  top: 21px;
}
.baxterCapitalize ::placeholder {
  text-transform: capitalize !important;
}
.IndexDataTableNeoParentScroll {
  display: block;
  width: 100%;
  overflow: auto;

  .scroollDataTableNeoScroll {
    white-space: initial;
    width: 100%;
    display: table;
    overflow: auto;
    flex-wrap: nowrap !important;
    .CardDateTableScroll1,
    .CardDateTableScroll {
      width: max-content;
      .IndexDatatableNeoRowScroll {
        display: flex;
        white-space: wrap;
        overflow-y: visible;
        flex-wrap: nowrap !important;
        width: 100%;
        max-width: -webkit-fill-available;

        .IndexDatatableNeoCol {
          text-align: left;
          padding-left: 100px;
        }
      }
    }
    .CardDateTableScroll {
      min-width: 128%;
    }
    .CardDateTableScroll1 {
      min-width: 100%;
    }
  }
}

.IndexDatatableNeoRow {
  display: flex;
  white-space: initial;
  flex-wrap: nowrap !important;
}
.IndexDatatableNeoCol {
  text-align: left;
  // padding-left: 50px;
}
.IndexDatatableNeoCol1 {
  // text-align: left;
  // padding-left: 43px;
  height: 50px;
  .rs-picker {
    border: 1px solid #e6e6e6;
  }
  .rs-picker-toggle-value {
    color: #6e6e6e !important;
    font-size: 13px;
    padding-top: 2px;
    height: 26px;
    left: 23px;
    margin-left: 15px;
  }
  .rs-picker {
    :placeholder {
      position: relative;
      left: 50px !important;
    }
  }
}
.inputIndex {
  // text-align: left;
  // padding-left: 43px;
  height: 50px;
  // margin-left: -31px !important;
  .rs-picker {
    border: 1px solid #e6e6e6;
  }
  .rs-picker-toggle-value {
    color: #6e6e6e !important;
    font-size: 13px;
    padding-top: 2px;
    height: 26px;
    left: 23px;
    margin-left: 15px;
  }
  .rs-picker {
    :placeholder {
      position: relative;
      left: 50px !important;
    }
  }
}
.resetSearchBar {
  width: 140px;
  float: right;
  height: 22px;
  line-height: 0px;
  background-color: #0ab38c;
  font-size: 12px;
  margin-right: 15px;
}
.myResearSearchBar {
  width: 140px;
  float: right;
  height: 22px;
  line-height: 0px;
  background-color: #0ab38c;
  font-size: 12px;
  margin-right: 15px;
  margin-top: 16px;
}
.searchToggleDiv {
  height: 150px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -8px !important;
  padding-bottom: 35px;
}
.orderList .searchToggleDiv {
  height: 100px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -10px !important;
}
.productList .searchToggleDiv {
  height: 150px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -10px !important;
}
.dispatchList .searchToggleDiv {
  height: 66px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -10px !important;
}

.roleIndex .searchToggleDiv {
  height: 66px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -9px !important;
}

.invoiceList .searchToggleDiv {
  height: 202px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: -9px !important;
}

.tripList .searchToggleDiv {
  height: 215px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}

.orderList .RenderInput {
  width: 95%;
  .select2-container input {
    border: 1px solid #e6e6e6;
  }
}

.RenderInput {
  width: 95%;
  .select2-container input {
    border: 1px solid #e6e6e6;
  }
}
@media (max-width: 640px) {
  .RenderInput {
    width: 98%;
  }
  .IndexDataTableNeoParent {
    display: block;
    width: 100%;
    overflow: auto;

    .scroollDataTableNeo {
      // display: contents;
      white-space: initial;
      // overflow-y: hidden;

      width: 100%;
      display: table;
      overflow: auto;
      flex-wrap: nowrap !important;
      // padding: 0px 15px;

      .CardDateTable {
        width: max-content;
        min-width: 200%;
        .IndexDatatableNeoRowScroll,
        .IndexDatatableNeoRow {
          display: flex;
          white-space: nowrap;
          overflow-y: visible;
          flex-wrap: nowrap !important;
          width: 100%;
          max-width: -webkit-fill-available;

          .IndexDatatableNeoCol {
            text-align: left;
            padding-left: 100px;
          }
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .IndexDataTableNeoParent {
    display: block;
    width: 100%;
    overflow: auto;

    .scroollDataTableNeo {
      // display: contents;
      white-space: initial;
      // overflow-y: hidden;

      width: 100%;
      display: table;
      overflow: auto;
      flex-wrap: wrap !important;
      // padding: 0px 15px;

      .CardDateTable {
        width: max-content;
        min-width: 150%;
        .IndexDatatableNeoRowScroll,
        .IndexDatatableNeoRow {
          display: flex;
          white-space: wrap;
          overflow-y: visible;
          flex-wrap: wrap !important;
          width: 100%;
          max-width: -webkit-fill-available;

          .IndexDatatableNeoCol {
            text-align: left;
            padding-left: 100px;
          }
        }
      }
    }
  }
}
