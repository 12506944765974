.navbar-header-new-scroll{
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
    padding: 10px;
  }
  .user-dropdown{
    .dropDownRightProfile{
    width: 278px;
    right:2% !important;
    }
  }
  
  /* break points*/
  @media (min-width: 1625px) and (max-width: 1650px) {
    .user-dropdown{
      .dropDownRightProfile{
      right:10% !important;
      }
    }
  }
  @media (min-width: 1598px) and (max-width: 1624px) {
    .user-dropdown{
      .dropDownRightProfile{
      right:26% !important;
      }
    }
  }
  @media (min-width: 1500px) and (max-width: 1597px) {
    .user-dropdown{
      .dropDownRightProfile{
      right:40% !important;
      }
    }
  }
  @media (min-width: 1412px) and (max-width: 1462px) {
    .user-dropdown{
      .dropDownRightProfile{
      right:30% !important;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1411px) {
    .user-dropdown{
      .dropDownRightProfile{
      right:40% !important;
      }
    }
  }
  @media (min-width: 1000px) and (max-width: 1199px) {
    .user-dropdown{
      .dropDownRightProfile{
      right:50% !important;
      }
    }
  }
  @media (max-width: 1279px) {
    .user-dropdown{
      .dropDownRightProfile{
      right:55% !important;
      }
    }
  }
  @media (max-width: 640px){
    .user-dropdown{
        .dropDownRightProfile{
        right:5% !important;
        }
      }
  }