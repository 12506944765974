// ajit
.dark__mode {

  .QualityCheckCard  {
    background-color: #2e2e46;
  }
  .dashboardDateRange {
    .rs-picker-toggle-placeholder {
      color: #9d9d9d !important;
    }
    .rs-picker-toggle-value{
      color: #9d9d9d !important;
    }
  }
  .IndexDatatableNeoCol1 {
    .select2-selection__control,
    .rs-picker {
      border: 1px solid #6a5a5a;
    }
    .rs-picker-toggle-value {
      color: #6e6e6e !important;
    }
  }
  .user-dropdown {
    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: #1b1c31;
    }
  }
  .addIndentDarkColor {
    color: #9d9d9d;
  }

  .addLoadingPoint {
    font-size: small;
    font-weight: 1000;
    color: black;
    font-family: Poppins;
  }

  .darkBackgroundBorder {
    background-color: #1b1c31;
    border: 1px solid #616161;
  }

  .darkReportTime,
  .darkAgreementVehicleCharge,
  .darkQuickAgreement {
    .dateTime {
      input {
        background-color: #2e2e46;
      }
    }
    .rdtPicker {
      background-color: #2e2e46;
    }
    .rdtSwitch,
    .rdtTimeToggle {
      color: #9d9d9d;
    }
  }

  .addIntentDarkModal {
    .dateTimeInputParent {
      background-color: #2e2e46 !important;
    }
  }
  .darkInputHighlightDiv {
    background-color: #2e2e46 !important;
  }

  .invoiceDarkMode .modal-content {
    background-color: #1b1c31;
  }

  .headersModalColor {
    .modal-title {
      color: #9d9d9d !important;
    }
  }

  .darkmodeStatusMode {
    color: #438d19;
  }

  .darkIndentModalDetails {
    .MuiPaper-root {
      background-color: #1b1c31 !important;
    }
  }

  .darkIndentModalRejection {
    background-color: #171722 !important;
  }

  .darkIntransitModalPopup {
    .MuiPaper-elevation {
      background-color: #24243c;
    }
  }

  .darkModeInvoiceState {
    .react-select__control {
      background-color: #2e2e46;
    }
  }

  .darkInvoiceModalCharge {
    background: #2e2e46 !important;
    color: #9d9d9d;
  }

  .darkInviceModalIcons {
    background-color: #b7a9ff;
  }

  .darkModeDriver {
    .MuiDialog-scrollPaper {
      .MuiDialog-paper {
        background-color: #1b1c31 !important;
      }
    }
    .select2-selection__menu {
      background-color: #171722;
      color: #b7a9ff;
    }
    .css-1pahdxg-control,
    .css-yk16xz-control,
    input {
      background: #2e2e46;
      border: 0.5px solid #616161;
      border-radius: 4px;
      color: #ffffff;
    }
    .select2-selection__indicators {
      background: #2e2e46;
    }
    .select2-selection__cont {
      background-color: #2e2e46;
    }

    .css-yk16xz-control {
      background-color: #2e2e46;
    }
  }
  .darkDropDownAddIndent {
    .select2-selection__menu {
      background-color: #171722;
      color: #b7a9ff;
      &:hover {
        background-color: #1b1c31;
      }
    }
  }

  .invoiceDropdown,
  .costRevenuPadding,
  .OperationaExport {
    // .react-select__indicator{
    //   padding : 6px !important;
    // }
    .react-select__dropdown-indicator {
      color: #b7a9ff !important;
    }
  }
  .orderHistoryModel{
    .MuiPaper-root{
     background: #1b1c31;
    }
 }
 .ivrDiv{
  background-color: #171722 !important;
 }
 .orderDriverBtn{
  background-color: #5e40ff!important;
 }
 .react-datepicker,.react-datepicker__header{
  background-color: #2e2e46 !important;
 }
}
// ajit
//start
.dark__mode {
  background-color: #171722 !important;
}
.light__mode {
  background-color: white !important ;
}
.dark__mode {
  .darkInternalcard,
  .consentBackground {
    background: #2e2e46 !important;
    border: none !important;
  }
  .darkInterInvoice {
    background: #2e2e46 !important;
    border-radius: 4px;
  }
  .darkCardStyle {
    background-color: #24243c !important;
    border: 0.5px solid #616161 !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.129);
    border-radius: 4px;
  }
  .darkInvoiceCard {
    background: #24243c;
    border: 0.5px solid #616161;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.129);
    border-radius: 4px;
    border-bottom: none;
  }

  .indentDexpiry {
    background-color: #2e2e46 !important;
    border: none !important;
  }

  .darkdountchart {
    background: #171722 !important;
  }

  .dashboard-header-div
    .dashboard-header
    .date-picker-div
    .darkDatepicker-selector
    .MuiInputBase-input {
    color: #9d9d9d !important;
  }

  .themeDarkChartText,
  .apexBgDarkCard,
  .barBgDarkCard,
  .darkInputHighlightDiv {
    .apexcharts-yaxis text {
      fill: #9d9d9d !important;
      font-weight: 600;
    }
    .apexcharts-xaxis text {
      fill: #9d9d9d !important;
      font-weight: 600;
    }
  }

  .darkChartOpDash {
    background: #171722 !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px !important;
  }

  .transDarkCard {
    background: #2e2e46 !important;
    border: 1px solid #616161;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    // color: #9D9D9D;
    .css-1pahdxg-control,
    .css-yk16xz-control,
    input {
      background: #2e2e46;
      border: 0.5px solid #616161;
      border-radius: 4px;
      color: #ffffff;
    }
  }

  .transDarkCard {
    .tripHighlights {
      background: #1b1c31 !important;
      border-radius: 4px;
    }
    .highlightsTrips {
      background: #373756 !important;
      box-shadow: 0px 0px 4px rgba(17, 17, 17, 0.12) !important;
      border-radius: 4px !important;
    }
  }

  .darkAgreementVehicleCharge {
    .css-1pahdxg-control,
    .css-yk16xz-control,
    input {
      background: #2e2e46;
      border: 0.5px solid #616161;
      border-radius: 4px;
      color: #ffffff;
    }
    .select2-selection__indicators {
      background: #2e2e46;
    }
    .select2-selection__menu {
      background-color: #171722 !important;
      color: #b7a9ff !important;
    }
  }
  .progressBg,
  .darkCardStyle {
    .progress {
      background: #171722;
      border-radius: 1px;
      border: none;
    }
  }

  .indentExpiry {
    background-color: #24243c;
  }

  .verticalMenuDark {
    background: #171722;
    color: white;
  }

  .darkMultipleCharges {
    background-color: #24243c;
    color: #9d9d9d;
  }

  .darkQuickAgreement {
    .select2-container {
      .rdt {
        input {
          background-color: #2e2e46;
        }
      }
    }
  }
  .darkDataTableCharges {
    .MuiPaper-root {
      background-color: #1b1c31;
    }
  }
  .darkTransporterModalAgreement {
    background: #2e2e46 !important;
    color: #ffffff;
  }
  .darkCalenderCharges {
    background-color: #2e2e46 !important;
    color: #b7a9ff !important;
  }
  .darkInvoiceEdit {
    background-color: #24243c;
  }
  .darkInvoiceModalIcons::before {
    background-color: #1b1c31 !important;
    color: #b7a9ff !important;
  }
  .darkAgreementAddToList {
    background-color: #5e40ff !important;
  }
  .darkCommentText {
    .MuiInputBase-root {
      color: #ffffff !important;
    }
  }
  .MuiPaginationItem-root 
    // .modal-title{
  {
    color: #9d9d9d;
  }
  .close {
    color: #b7a9ff !important;
  }
  //Edituser Table
  .paginateDarkText {
    .table {
      color: #9d9d9d !important;
    }
    .page-link {
      background-color: #2e2e46;
      color: #9d99d9;
    }
  }

  .css-1uccc91-singleValue {
    color: #9d9d9d;
  }
}
//DarkActionDropDown
.dark__mode {
  .actionButtonsDark {
    background-color: #24243c;
    border: 0.5px solid #b7a9ff;
  }

  .actionButtonsDark:hover {
    background-color: #b7a9ff;
    transition: 0.7s;
  }

  .barDropDark {
    background-color: #171722;
    border: 0.5px solid #616161;
  }

  .barDropDark {
    .dropdown-item:hover {
      background-color: #24243c;
    }
  }
  .CarbonFootPrintDiv .react-select__dropdown-indicator {
    color: #b7a9ff;
  }

  .contentDark {
    background: #171722;
    border: 0.5px solid #616161;
  }
  .contentDark div:hover {
    background: #1b1c31;
  }

  .actionDrspan {
    color: rgb(108, 7, 7);
  }

  .actionLine {
    background-color: #b9b9b9 !important;
    width: 94%;
    height: 0px;
    margin: 0px 3px 0px 3px;
    padding: 0px;
  }
  //listing
  .darkBackgroundList {
    background-color: #1b1c31 !important;
  }
  .darkBackgroundList,
  .darkBgQuickFilter {
    .form-control,
    .css-bmaph7-control {
      background-color: #2e2e46 !important;
      color: #9d9d9d !important;
      border: 0.5px solid #616161 !important;
    }
  }

  .darkBgQuickFilter {
    .css-6r80l5-placeholder {
      color: #9d9d9d;
    }
  }
  .darkBgQuickFilter {
    .input-border {
      background-color: #2e2e46;
      border: 1.5px solid #b7a9ff;
    }
    .input-line {
      border-left: 2px solid #b7a9ff;
    }
  }
  .darkBgQuickFilter {
    background-color: #222238;
  }
  .darkStatusCompleted {
    color: #069406;
    text-transform: capitalize;
  }
  .darkReportIncident,
  .modelDark {
    .select2-selection__control,
    .select2-selection1__control,
    .select2-selection__indicators,
    .select2-selection1__indicators {
      background-color: #2e2e46;
      .css-6q0nyr-Svg {
        fill: #b7a9ff !important;
      }
    }
  }

  .darkReportIncident {
    .select2-selection__menu {
      background-color: #171722 !important;
      color: #b7a9ff !important;
    }
  }

  // search select indent trip listing
  .css-1j5bupo-control,
  .css-1m07g1q-control {
    background-color: #24243c !important;
  }

  // select option text search color
  .css-h5jgki-Input,
  .css-b8ldur-Input {
    color: #9d9d9d;
  }

  .darkBackgroundList {
    .css-67v38y {
      color: #9d9d9d;
    }
  }

  .darkBgQuickFilter {
    .react-select__option {
      background-color: #171722;
      color: #b7a9ff;

      &:hover {
        background-color: #1b1c31;
      }
    }
    .react-select__menu {
      background-color: #171722;
    }
  }

  .darkText {
    color: #ffffff !important;
  }

  .lightText {
    color: #9d9d9d !important;
  }

  .actionDspan {
    color: #b7a9ff !important;
  }

  .table-hover tbody tr:hover td {
    background: #2e2e46 !important;
  }

  .highDarkTrip {
    border: 1px solid #0ab38c;
    background: #2e2e46 !important;
    color: black !important;
  }

  .light__span {
    color: #9d9d9d !important;
  }

  .dark__span {
    color: #ffffff !important;
  }
  .profile-chervon {
    color: #b7a9ff;
  }

  ::placeholder {
    color: #9d9d9d;
    opacity: 2;
  }

  .darkBackgroundList {
    ::placeholder {
      color: #9d9d9d;
      opacity: 2;
    }

    :-ms-input-placeholder {
      color: #9d9d9d;
    }

    ::-ms-input-placeholder {
      color: #9d9d9d;
    }
  }
}

//apexchart
.dark__mode {
  .barBgDarkCard {
    background: #24243c !important;
    border: 1px solid #616161;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  .barBgDark {
    background: #171722;
  }

  .apexBgDarkCard {
    background: #171722 !important;
    border: 1px solid #616161;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  //quickfilterIndentTrip
  .indent-quick-filters,
  .trip-quick-filters {
    .delayDarkStyle {
      background-color: #171722;
      color: #ffffff !important;
      border: 0.1px solid #ac1000;
    }
    .activeDarkStyle {
      background-color: red;
      color: white !important;
      box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.88);
    }
  }

  //quickicon
  .iconDarkButtons {
    border: 1.5px solid #b7a9ff !important;
    background-color: #222238 !important;
  }

  .exportDark {
    fill: #2e2e46 !important;
  }

  //icon sepration
  .lineDark {
    border-left: 2px solid #b9b9b9;
  }
  .lineHr {
    border-top: 0.1px solid #b9b9b9;
  }
  .inactiveDark {
    background: #171722 !important;
    border: 0.5px solid #616161 !important;
    color: #ffffff !important;
  }
  .trackDots {
    color: #9682ff !important;
  }
  .darkDashed {
    border-left: 3px dashed #9682ff;
  }
}

//modelbg
.dark__mode {
  .ProfileMainCards {
    .darkTexBox {
      background: #2e2e46;
      border: 0.5px solid #616161;
      border-radius: 4px;
      color: #ffffff;
    }
    .toggle-switch-inner:after {
      background-color: #565050;
    }
    .CardPasswordFields {
      background: #2e2e46;
      color: #ffffff;
    }
    .PasswordShowButtons {
      background: #2e2e46;
      border-color: #2e2e46;
    }
  }
  // .shipModel,.tripModel{
  .modal-content {
    background: #1b1c31;
    border: 0.5px solid #616161;
    .css-1m07g1q-control,
    .css-1d6n8ck-control,
    input {
      background-color: #2e2e46 !important;
      color: #ffffff;
    }

    .modal-title {
      color: #9d9d9d;
    }
    .closeDark,
    .textHead {
      color: #b7a9ff !important;
    }

    .darkTexBox {
      background: #2e2e46;
      border: 0.5px solid #616161;
      border-radius: 4px;
      color: #ffffff;
    }
    .addNewBg {
      background: #373756 !important;
      border-radius: 4px;
    }
  }
  //Wholepage dark bg
  .darkContain {
    background-color: #171722 !important;
  }

  .modelDark {
    .concentMsg {
      background: #171722;
      border: 0.5px solid #0ab38c;
    }
    .driverConsentDarkdiv {
      background-color: #171722;
    }
    .addDarkBtn {
      background-color: #5e40ff !important;
    }
  }

  @media (max-width: 640px) {
    .navbar-header-new {
      background-color: rgb(55, 55, 86) !important;
    }
  }
}
