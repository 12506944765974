input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #0c0c0c;
  padding-left: 18px;
}

.dateTime input[type="text"] {
  background-color: white;
  border: none;
  height: 45px;
}

.headerContent {
  justify-content: space-between;
  height: 40px;
  background-color: #f1f5f7;
}

.breadcrumbInactive {
  color: #0c0c0c;
  font-weight: 400;
  height: 14px;
  font-size: 12px;
  font-family: Verdana, sans-serif;
  margin: 3px;
}

.breadcrumbActive {
  color: #2800fc;
  font-weight: 400;
  height: 14px;
  font-size: 12px;
  font-family: Verdana, sans-serif;
  margin: 3px;
}

.breadcrumbSeparator {
  color: #b9b9b9;
  font-weight: 400;
  height: 14px;
  font-size: 12px;
  font-family: Verdana, sans-serif;
  margin: 3px;
}

.refreshIcon {
  color: #000000;
  font-weight: 900;
  height: 14px;
  font-size: 12px;
  font-family: Verdana, sans-serif;
  margin: 6px;
}

.topImage {
  margin: 6px;
}

.topImage img {
  width: 26px;
  height: 26px;
}

.userName {
  color: #0c0c0c;
  font-weight: 400;
  height: 14px;
  font-size: 12px;
  font-family: Verdana, sans-serif;
  margin: 6px;
}

.userName span {
  color: #2800fc;
  font-weight: 900;
  height: 14px;
  font-size: 12px;
  font-family: Verdana, sans-serif;
  margin: 6px;
}

.containerWhiteDivider {
  padding-top: 10px !important;
  height: 50px;
  width: 100%;
  margin-left: 0%;
  background-color: #ffffff;
}

.formFieldHeaderStyle {
  font-weight: bold;
  font-size: 15px;
  font-style: normal;
  font-family: Verdana, sans-serif;
  color: #05284e;
}

.mandatoryFieldStyle {
  font-weight: 700;
  font-size: 15px;
  font-style: normal;
  font-family: Verdana, sans-serif;
  color: red;
}

.resetButtonStyle {
  background-color: #0ab38c;
  border-radius: 3px;
  height: 50px;
  width: 120px;
  margin: auto;
  margin-right: 9px;
}

.resetButtonSpanStyle {
  margin: auto;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  font-family: Verdana, sans-serif;
  color: #ffffff;
}

.submitButtonStyle {
  background-color: #05284e;
  border-radius: 3px;
  height: 50px;
  width: 120px;
  margin: auto;
  margin-right: 9px;
}

.submitButtonSpanStyle {
  margin: auto;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  font-family: Verdana, sans-serif;
  color: #ffffff;
}

.cancelIndentButtonStyle {
  background-color: #dc3545;
  border-radius: 3px;
  height: 50px;
  width: 150px;
  margin: auto;
  margin-right: 9px;
}

.cancelIndentButtonSpanStyle {
  margin: auto;
  font-weight: normal;
  font-size: 15px;
  font-style: normal;
  font-family: Verdana, sans-serif;
  color: #ffffff;
}

.calendarSeparator {
  margin: auto;
  height: 24px;
  border-left: 0.5px solid #b9b9b9;
  margin-right: 0.7%;
}

.transporterSeparator {
  margin: auto;
  height: 24px;
  border-left: 0.5px solid #b9b9b9;
  margin-right: 4%;
}

.gridContainer {
  display: flex;
  margin: 0%;
  border: 1px solid lightgray;
  background-color: #ffffff;
  border-radius: 5px;
}

.dateTimeInputParent {
  border: 0.5px solid #aeaeae;
  height: 50px;
  border-radius: 5px;
}

.calendarSpanStyle {
  border: 0.5px solid #2800fc;
  margin: 25%;
  border-radius: 5px;
  background-color: #f1f5f7;
  height: 25px;
  width: 43px;
}

.plusSpanStyle {
  border: 0.5px solid #2800fc;
  margin: 30%;
  border-radius: 5px;
  background-color: #f1f5f7;
  height: 25px;
  width: 25px;
}
