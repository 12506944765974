//
// authentication.scss
//

.auth-body-bg {
  background-color: $card-bg;
}

.authentication-bg {
  height: 100vh;
  img{
    height: 100%;
    width: auto;
  }
  background-size: cover;
  background-position: center;
  .bg-overlay {
    background-color: #292626;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.authentication-page-content {
  height: auto;
  display: flex;
  flex: 1
}

.auth-form-group-custom {
  position: relative;
  .form-control {
    height: 60px;
    padding-top: 28px;
    padding-left: 60px;
  }

  label {
    position: absolute;
    top: 7px;
    left: 60px;
  }

  .auti-custom-input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 19px;
    font-size: 24px;
    color: $primary;
  }
}

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 9;
}

.authentication-bg-new {
 
  img {
    position: absolute;
    width:100%;
    height: 100%;
    left: 0px;
    top: 0px;
    object-fit: fill;
  }
  .img-spinclabs {
    position: absolute;
    width: 199px;
    // height: 87px;
    left: 50px;
    top: 50px;
  }
  .img-transport-system {
    
    position: absolute;
    left: 5.5%;
    right: 64.62%;
    top: 78.25%;
    bottom: 39.59%;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 41px;
    text-decoration-line: underline;
    font-weight: 700;
    color: #a4e0ff;
  }
  .copy-rights{
    position: absolute;
    // width: 276px;
    left: 5.47%;
    right: 77.36%;
    top: 91.01%;
    bottom: 4.08%;
    height:14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #ffffff;
  }
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }
}

.auth-form-group-custom-login {
  position: relative;
  .form-control {
    height: 50px;
    padding-left: 60px;
  }
  .auti-custom-input-icon {
    position: absolute;
    top: 13%;
    left: 19px;
    font-size: 24px;
    color: $primary;
  }
  .form-control:hover,.form-control:focus{
    border: 1px solid rgb(23, 245, 234);
  }
}
.login-page-container {
  background: #ffffff;
  position: relative;
  width: 1440px;
  height: 735px;
}

.authentication-login-page-content {
  position: absolute;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 525px;
  height: 735px;
  background: hsl(0, 0%, 100%);
  overflow-y: hidden;
}




