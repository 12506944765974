.reportStyle {
  @media (max-width: 750px) {
    .cardStyleR {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 750px) {
    .cardStyleR.filterR {
      flex: 1 0 100%;
      margin-left: 0 !important;
    }
  }

  .quickStylesBar .repotCal .MuiPickerStaticWrapper-root {
    top: 17%;
  }
}
