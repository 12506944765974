.bordering p {
  font-size: 12px;
}

/* .bordering.second {
  position: relative;
  top: -4px;
  left: -29px;
} */


  .bordering {
    /* background: url(https://www.cloudesign.com/wp-content/uploads/2022/03/Vector.png);
    background-position: center;
    background-size: cover; */
    padding: 10px;
    /* height: 112px; */
    /* max-width: 15% !important; */
    margin: 0px auto;
  }
  .bordering p span {
    font-size: 9px;
  }
  .square {
    background-color: #fff;
    width: 170px;
    height: 115px;
    margin: auto;
    position: relative;
    padding: 15px;
    border: 0px;
    border-radius: 8px;
  }
  .TripDetailsDiv .bottom::after {
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: -11px;
    background-color: #fff;
    transform: rotate(225deg);
  }
  .TripDetailsDiv .top::after {
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    top: -11px;
    background-color: #fff;
    transform: rotate(45deg);
  }
  .frist {
    top: 25px;
    right: -15px;
    border: #ff6f00 solid 1px;
    border-top-width: 4px;
  }
  .frist::after {
    right: 35px;
    border-top: #ff6f00 solid 1px;
    border-left: #ff6f00 solid 1px;
  }
  .second {
    top: -15px;
    right: 30px;
    border: #ac1000 solid 1px;
    border-top-width: 4px;
  }
  .second::after {
    right: 35px;
    border-top: #ac1000 solid 1px;
    border-left: #ac1000 solid 1px;
  }
  .third {
    top: 20px;
    left: 5px;
    border: #a020f0 solid 1px;
    border-top-width: 4px;
  }
  .third::after {
    left: 25px;
    border-top: #a020f0 solid 1px;
    border-left: #a020f0 solid 1px;
  }
  .fourth {
    top: 75px;
    right: 100px;
    border: #006400 solid 1px;
    border-top-width: 4px;
  }
  .fourth::after {
    left: 25px;
    border-top: #006400 solid 1px;
    border-left: #006400 solid 1px;
  }
  .fifth {
    bottom: 60px;
    right: -50px;
    border: #e19600 solid 1px;
    border-bottom-width: 4px;
  }
  .fifth::after {
    right: 25px;
    border-top: #e19600 solid 1px;
    border-left: #e19600 solid 1px;
  }
  .sixth {
    top: -15px;
    left: 0;
    border: #28a34c solid 1px;
    border-bottom-width: 4px;
  }
  .sixth::after {
    right: 25px;
    border-top: #28a34c solid 1px;
    border-left: #28a34c solid 1px;
  }
  .seventh {
    top: 20px;
    left: -10px;
    border: #8f00ff solid 1px;
    border-bottom-width: 4px;
  }
  .seventh::after {
    right: 25px;
    border-top: #8f00ff solid 1px;
    border-left: #8f00ff solid 1px;
  }
  .eighth {
    top: -15px;
    left: 0;
    border: #ff6f00 solid 1px;
    border-bottom-width: 4px;
  }
  .eighth::after {
    left: 25px;
    border-top: #ff6f00 solid 1px;
    border-left: #ff6f00 solid 1px;
  }


  /* tripAllDetail css */
  .frist1 {
    top: 29px;
    right: 0px;
    border: #ff6f00 solid 1px;
    border-top-width: 4px;
  }
  .frist1::after {
    right: 35px;
    border-top: #ff6f00 solid 1px;
    border-left: #ff6f00 solid 1px;
  }
  .second1 {
    top: 38px;
    left: 10px;
    border: #ac1000 solid 1px;
    border-top-width: 4px;
  }
  .second1::after {
    right: 35px;
    border-top: #ac1000 solid 1px;
    border-left: #ac1000 solid 1px;
  }
  .third1 {
    top: 20px;
    left: 5px;
    border: #a020f0 solid 1px;
    border-top-width: 4px;
  }
  .third1::after {
    left: 25px;
    border-top: #a020f0 solid 1px;
    border-left: #a020f0 solid 1px;
  }
  .fourth1 {
    top: 34px;
    /* right: 100px; */
    border: #006400 solid 1px;
    border-top-width: 4px;
  }
  .fourth1::after {
    left: 25px;
    border-top: #006400 solid 1px;
    border-left: #006400 solid 1px;
  }
  
  .completedFourth {
    top: 59px;
    left: 110px;
    border: #006400 solid 1px;
    border-bottom-width: 4px;
  }
  .completedFourth::after {
    left: 25px;
    border-top: #006400 solid 1px;
    border-left: #006400 solid 1px;
  }
  .fifth1 {
    bottom: 60px;
    right: -50px;
    border: #e19600 solid 1px;
    border-bottom-width: 4px;
  }
  .fifth1::after {
    right: 25px;
    border-top: #e19600 solid 1px;
    border-left: #e19600 solid 1px;
  }
  .sixth1 {
    top: 8px;
    left: 0;
    border: #28a34c solid 1px;
    border-bottom-width: 4px;
  }
  .sixth1::after {
    right: 25px;
    border-top: #28a34c solid 1px;
    border-left: #28a34c solid 1px;
  }
  .seventh1 {
    top: 20px;
    left: -10px;
    border: #8f00ff solid 1px;
    border-bottom-width: 4px;
  }
  .seventh1::after {
    right: 25px;
    border-top: #8f00ff solid 1px;
    border-left: #8f00ff solid 1px;
  }
  .eighth1 {
    top: 20px;
    left: 0;
    border: #ff6f00 solid 1px;
    border-bottom-width: 4px;
  }
  .eighth1::after {
    left: 25px;
    border-top: #ff6f00 solid 1px;
    border-left: #ff6f00 solid 1px;
  }
  .paraHeadSource {
    color: #4c5b66;
    font-weight: bold;
    font-family: "Roboto";
    line-height: 14px;
  }


  .responsive {
    width: 100%;
    height: auto;
  }

/**********************/

.darkTripDetails{
  background-color: #171722 !important;
}
.darkTripDetails::after{
  background-color: #171722 !important;
}