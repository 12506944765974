//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";
// @import '../node_modules/react-notifications/lib/notifications.css';
//Import Dark theme
@import "./themeDark.scss";
@import "./themeQuickFilters.scss";
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";
@import "./components/CommonForBoth/TopbarDropdown/ProfileMenuBar.scss";
@import "./components/Common/DataTableNeo/Styles/tableStyles.scss";
@import "./pages/Trips/tripStyles.scss";
@import "./loader.scss";
@import "./pages/ProfileSettings/ProfileSettings.scss";

.ptL5 {
  padding-left: 5px;
}

.ptL10 {
  padding-left: 10px;
}

.fontRoboto {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
}

.fontRoboto618 {
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
}

.fontRoboto12 {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
}

.fontRoboto127 {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
}

.fontRoboto137 {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
}

.fontRoboto134 {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
}

.fontRoboto84 {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
}

.fontRoboto87 {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
}

.fontRoboto17 {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
}

.fontRoboto177 {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-size: 17px;
}

.fontRoboto15 {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
  color: #05284e;
}

.fontRoboto154 {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  color: #05284e;
}

.fontRoboto1541 {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 15px;
  /* height: 12px; */
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #e4e4e4;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  position: absolute;
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

::-webkit-scrollbar-thumb:active {
  // background: #05284e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  position: absolute;
  background: #26435e;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.p-3 {
  position: absolute;
  top: 0%;
  left: 90%;
  cursor: pointer;
}

.p-4 {
  position: absolute;
  top: 0%;
  left: 60%;
  cursor: pointer;
}

.modal-head {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.modalTitle {
  font-size: 20px;
  line-height: 18px;
  color: "#0C0C0C" !important;
}

.modalBodyTitle {
  font-size: 15px;
  color: "#05284E";
}

.modalFooterStyle {
  border-top: 0 none;
  display: flex;
  justify-content: flex-start;
}

.rejectModalWarning {
  background: #ffe3e1;
  border: 0.5px solid #e53d2c;
  border-radius: 4px;
  height: 37px;
  padding: 7px;
}

.drop-down-custom {
  color: #5664d2;
}

.reset {
  // position: absolute;
  // width: 104px;
  // height: 37px;
  background: #333333;
}

.search-icon-color {
  background-color: #5664d2;
}

.rdt .form-control {
  background-color: #f1f5f7;
}

.calendar-icon-color {
  background: #505d69;
  opacity: 0.25;
}

.cal {
  line-height: 2;
  border-radius: 10%;
  margin-left: 1rem;
}

.calendar-icon-color i {
  color: black;
}

.table-row-collapse {
  width: 100%;
  background-color: #f1f5f7;
  padding-top: 20px;
  margin-bottom: 20px;
}

.t-cell td {
  width: 5%;
}

.colsp {
  width: 19%;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}

.mdl {
  max-width: 1000px !important;
}

.mdl .modal-content {
  height: 510px !important;
}

.invoice .mdl .modal-content {
  height: auto !important;
  padding-bottom: 20px;
}

.last {
  padding-top: 1rem;
  margin-left: 1rem;
}

.last div {
  padding: 0px;
}

.colaps-rows {
  color: black;
  width: 100%;
}

.colaps-cols-top {
  width: 50%;
  text-align: right;
}

.colaps-cols,
.colaps-cols-first {
  color: black;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 21%;
}

.colaps-label {
  position: relative;
  left: 5.31%;
  right: 88.09%;
  top: 35.51%;
  bottom: 61.26%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;

  color: #252b3b;
  text-align: left;
  width: 100%;
}

.colaps-cols-first {
  height: 5%;
}

// #table-body .t-cell td {
//   width: 20%;
// }
.colaps-field {
  position: relative;
  left: 5.31%;
  right: 88.09%;
  top: 35.51%;
  bottom: 61.26%;

  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #252b3b;
  text-align: left;
  width: 100%;
}

.colaps-field .t-edit {
  height: 37px;
  border-radius: 2%;
  margin-right: 5px;
}

.colaps-field input {
  margin-right: 10px;
  height: 37px;
  color: #505d69;
  line-height: 18px;
}

.colaps-field button {
  line-height: 18px;
}

.t-edit {
  font-weight: lighter;
  background-color: #f5f5f7;
  color: #505d69;
}

.close-btn {
  position: relative;
  left: 19%;
  margin-top: 2%;
}

.invoice-modal-body {
  padding: 2rem;
}

.invoice-modal-body h5 {
  padding-left: 0;
}

.invoice-modal-body .calendar-icon-color {
  background: #505d69;
  opacity: 0.25;
  color: #505d69;
}

.invoiceinput label {
  padding-left: 0;
}

.invoiceinput input {
  background-color: #f1f5f7;
}

.invoice-modal-body .foot {
  justify-content: center;
}

.view-invoice {
  position: relative;
  left: 0.24%;
  right: 3.26%;
  top: 34.12%;
  bottom: 19.28%;

  background: #f1f5f7;
  padding: 2rem;
}

.view-row {
  padding: 2rem;
}

.view-col {
  padding: 0;
}

.view-col .label {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;

  color: #252b3b;
}

.view-col .field {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #252b3b;
}

.invoice-button {
  padding: 2rem;
}

.colaps-label-last {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;

  color: #252b3b;
  text-align: left;
  width: 20%;
  padding-left: 2rem;
  float: left;
}

.colaps-last-desc {
  width: 65%;
  float: left;
  margin-left: 5px;
  text-align: left;
}

.colaps-last {
  width: 17%;
  float: left;
  margin-left: 5px;
}

.colaps-last-btn {
  width: 15%;
  float: left;
  margin-top: 33px;
  margin-left: 6px;
}

.colaps-last {
  text-align: left;
}

.colaps-last div input {
  margin-right: 10px;
  height: 37px;
  color: #505d69;
  line-height: 18px;
}

Label {
  font-family: "Roboto";
}

.a-jumbo {
  margin-bottom: 1rem;
}

.bt {
  cursor: pointer;
}

.rs-picker:hover,
.rs-picker:focus {
  border: 3px solid rgb(79, 116, 238) !important;
  height: 32px !important;
  border-radius: 5px;
}

.rs-picker-toggle {
  padding: 0px 10px !important;
  border: none !important;
}

.rs-picker-toggle-clean {
  // position: absolute;
  // top: 0px;
  display: none;
}

.rs-picker-toggle-value {
  color: #0c0c0c !important;
  // margin-left: 0px;
  // margin-top: 5px;
  font-size: 13px;
  padding-top: 5px;
  height: 28px;
  // :focus{
  //   border: 1px solid blue;
  //   border-radius: 5px;
  // }
  // :hover{
  //   border: 1px solid blue;
  //   border-radius: 5px;
  // }
}

.jumbo {
  background-color: #f1f5f7;
  padding-top: 1rem;
}

.jumboButton {
  margin-top: 1.7rem;
}

.req {
  color: red;
}

.err {
  font-size: small;
  color: red;
}

.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.required_field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.align-center {
  text-align: center !important;
}

.underline {
  text-decoration: underline !important;
}

.accordian-bg-products {
  background-color: $gray-300;
}

.text-primary.as_m {
  margin-top: -5px;
}

.as_row {
  margin-top: -13px;
  margin-bottom: -13px;
}

.border-contant:before {
  content: "";
  position: absolute;
  /* left: 9px; */
  top: 43px;
  /* height: 2%; */
  width: 35%;
  border-top: 1px solid #eee;
}

.bb-1 {
  border-bottom: 1px solid #eee;
}

.as_row i {
  position: relative;
  top: 5px;
}

.bb-1 {
  border-bottom: 1px solid #eee;
}

.as_model .modal-content {
  background-color: #f1f5f7;
}

.Btn_Width {
  width: 100%;
}

.trasbit_btn {
  background: #8de2a9;
  border: 1px solid #369756;
}

.complate_btn {
  background: #8e9af8;
  border: 1px solid #3845a5;
}

.cancle_btn {
  background: #fec3c3;
  border: 1px solid #f64646;
}

.open_btn {
  border: 1px solid #f64646;
  background: #fec3c3;
}

.cancle_btn {
  background: #fec3c3;
  border: 1px solid #f64646;
}

.yellow_btn {
  background: #f8c16e;
  border: 1px solid #c58423;
}

.assigned_btn {
  background: cadetblue;
}

.as_colorbox .media {
  border-radius: 10px;
}

.bold_text {
  font-weight: 700;
}

.as_dashboard_table thead tr th:before {
  // position: absolute;
  content: "";
  position: absolute;
  left: -1px;
  width: 0px;
  top: 8px;
  border-left: 1px solid #eee;
  // border-left: 1px sold #fff;
  height: 77%;
}

.as_dashboard_table thead tr th {
  position: relative;
  vertical-align: inherit;
}

.as_dashboard_table tbody tr td:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 0px;
  top: 8px;
  border-left: 1px solid #eee;
  // border-left: 1px sold #fff;
  height: 76%;
}

.as_dashboard_table tbody tr td {
  position: relative;
}

/**************Start****/
.billing_pills_tab ul#v-pills-tab {
  background-color: #8e9af8;
  text-align: center;
}

.billing_tabs_media .media {
  align-items: center;
  height: 131px;
}

.billing_navtab.nav-justified .nav-item {
  max-width: 18%;
  background-color: #f1f5f7;
}

.billing_navtab.nav-pills .nav-link {
  padding: 12px 10px;
}

.billing_navtab.nav-pills .nav-link.active {
  border-radius: 0;
}

.billing_table_list .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f5f7;
}

.billing_table_list .table thead th,
.billing_table_list .table th,
.billing_table_list .table td {
  border-left: 1px solid #e7e7e7;
  padding: 15px;
}

.billing_table_list .table thead th:first-child,
.billing_table_list .table th,
.billing_table_list .table td:first-child {
  border-left: none;
}

// .billing_table_list .table th:last-child, .billing_table_list .table td:last-child{
//   border-bottom: 1px solid #efefef;
// }
.billing_table_list .table tr {
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}

.billing_table_list .table tr:last-child {
  border-bottom: 1px solid #e7e7e7;
}

.billing_table_list table .col_adjust {
  width: 45%;
}

.text-grey {
  color: #cfcfcf;
}

.billing_tabs_media b {
  font-weight: 500;
}

.flex.flex_end {
  align-items: flex-end;
  display: flex;
}

.display_flex_ul li {
  display: inline-flex;
}

.billing_table_list table a {
  color: #505d69;
}

.Bill_Invoice b {
  font-weight: 500;
}

.ajust_attachicon i {
  position: relative;
  top: 5px;
}

/***********end******************/
li.previous.disabled,
li.next.disabled {
  display: none;
}

.btn_indene_style button {
  width: 100px;
}

.lable_width {
  width: 30%;
}

/***********end******************/
.as_dashboard_table tbody tr {
  border: 2px solid #eee;
  margin: 10px 0px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.Model_inner .modal-content {
  border-radius: 0px;
  width: 40%;
  margin: 0px auto;
  background-color: #fff;
}

.Model_inner .modal-content .modal-body {
  border-radius: 0px;
}

.Model_inner .modal-content .close {
  background-color: #eee;
  border-radius: 50%;
  padding: 4px 8px;
  opacity: 1 !important;
}

.Model_inner .reject_model_box ul li {
  display: inline-block;
  list-style: none;
  padding-right: 20px;
}

.Model_inner .reject_model_box textarea {
  padding: 10px;
  color: #505d69;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

// .as_dashboard_table .table td {
//   // padding: 0.75rem;
//   // box-shadow: 0 1px 5px rgb(0 0 0 8%);
//   // border: 1px solid #eff2f7;
// }
.dashboard-cursor-eye:hover,
.dashboard-cursor-eye:focus {
  transform: scale(1.5);
  cursor: pointer;
  font-size: 30px;
  transition-delay: 3ms;
}

.dashboard-cursor-eye {
  cursor: pointer;
  // transform: scale(0.5);
}

.gray-bg {
  background: #8080801f;
}

.order {
  tbody {
    tr {
      cursor: pointer !important;
    }
  }
}

.tracking-table {
  tbody {
    tr {
      cursor: pointer !important;
    }
  }
}

.dataTables_filter,
.dataTables_paginate {
  float: right;

  .pagination {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
  }
}

////calendar icon
.calendar-icon {
  padding-left: 0.125rem;
}

.calendar-icon .fa-calendar,
.calendar-icon .fa-hand-point-up {
  margin-top: 2.5rem;
  line-height: 35%;
  border-radius: 20%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: rgba(183, 186, 183, 0.42);
}

.form-side-img {
  height: 500px;

  img {
    width: auto;
    height: 100%;
  }
}

.form-side-indentData-img {
  height: auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.form-shadow {
  box-shadow: #e6e7e9 5px 5px 5px 10px;
}

.form-header {
  font-size: x-large;
  font-weight: 1000;
  color: black;
}

.header {
  font-size: 20px;
  font-weight: bold;
}

.col-form-label {
  font-size: small;
  font-weight: 1000;
  color: black;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.react-bootstrap-table {
  padding-top: 0px !important;
}

.square-switch {
  .react-switch-bg {
    border-radius: 5px !important;
  }
}

input[type="range"] {
  -webkit-appearance: auto !important;
}

.chat-conversation-height {
  min-height: 500px;
}

.expand-cell {
  cursor: pointer !important;
}

//datetimepicker
/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  right: 0;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #dddddd;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

.rdtPicker th.rdtSwitch {
  font-size: 15px;
  width: 100px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters>div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.scroll-hide {
  overflow-y: hidden !important;
}

.modalPaddingBottom {
  padding-bottom: 7px;
}

.noLoading {
  padding-left: 18px;
  color: red;
  text-transform: none;
}

.verifyPadding {
  button {
    right: -13%;
  }
}

.alignWithLocation {
  padding-top: 0px !important;
}

.trackingBackground {
  position: relative;
  height: 100%;
  // background-color: #fff;
}

.trackingAbosulte {
  position: absolute;
  left: 8px;
  top: 8px;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  height: 756px;
  width: 29%;
  // overflow: auto;
}

.mapsLeftArrowImg {
  position: absolute;
  top: 8px;
  left: 29.5%;
  padding-left: 5px;
  cursor: pointer;
}

// .trackingAbosulte img{
//   position: absolute;
//   left: 371px;
//   top: 0px;
//   padding-left: 20px;
//   cursor: pointer;
// }
.trackingAbosulte .css-2b097c-container {
  width: 50%;
}

.highlightsTrips {
  border: 1px solid #0ab38c;
  background: #f1f5f7 !important;
}

.trackingAbosulte table {
  table {
    background-color: #fbfbfb;
    border-radius: 5px;
  }
}

.trackingAbosulte table:active {
  border: 1px solid #0ab38c;
  background: #f1f5f7 !important;
}

.trackingAbosulte table tr td {
  padding: 4px;
}

.trackingAbosulte table tr td:first-child {
  width: 42% !important;
}

.trackingAbosulte table tr td:last-child {
  width: 70% !important;
}

.sourceTransist {
  color: #05284e;
  font-weight: bold;
}

.detailTracking {
  position: absolute;
  top: 46px;
  right: 5px;
}

.rightArrowMaps {
  position: absolute;
  top: 10px;
  left: 9px;
  cursor: pointer;
}

.detailTracking li {
  text-align: center;
  list-style: none;
  padding: 5px;
}

.detailTracking ul {
  // background-color: #fff;
  padding-left: 0px !important;
}

.detailTracking ul li {
  color: #2800fc;
}

.detailTracking img {
  cursor: pointer;
}

.detailsTripMaps {
  position: absolute;
  top: 15px;
  right: -128px;
  width: 32%;
}

.detailTracking {
  .tooltip2 {
    border-bottom: none;

    .tooltiptext {
      margin-left: -77px;
    }
  }
}

.rightNavigationdiv {
  position: relative;
  top: 36px;
  right: 44px;
  cursor: pointer;
}

.noDataRecord {
  height: inherit;
  font-size: 18px;
  font-weight: 500;
}

.tripDetails,
.userDetails {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 30px 10px 10px;
  margin-bottom: 10px;
  width: 70%;
}

.logisticsPadding {
  margin-bottom: 4px;
}

.userBtn {
  width: 100%;
  margin-top: 28px;
}

.enteriesInnerContent thead {
  background-color: #505d69;
  color: white;
  text-align: left;
}

.enteriesInnerContent tbody {
  text-align: left;
}

.textRole {
  text-transform: capitalize;
}

.hopSelect .select2-selection__value-container {
  flex-wrap: nowrap;
}

.trackingAbosulte input {
  width: 45%;
  border-radius: 0.25rem;
}

.marginReset .card {
  margin-bottom: 6px;
}

.vehicleAddress {
  background-color: #f1f5f7;
  padding: 6px;
}

.highlightsQuickFilter {
  color: #ffe3e1 !important;
  background-color: #e53d2c;
}

.enteriesInnerContent .dataTables_length {
  display: none;
}

.enteriesInnerContent {
  .dataTables_info {
    text-align: left;
    margin-left: 2px;
  }

  .hopCodePaddingDiv {
    padding-left: 17px;
  }
}

// .mapFullHeightDiv {
//   height: 100vh;
// }

.dropDownDelete {
  margin-left: 20px !important;
}

.apexChartIcon .apexcharts-toolbar,
.apexcharts-point-annotation-marker {
  display: none;
}

.apexChartIcon {
  .apexcharts-canvas {
    position: relative;
    width: 100% !important;
    min-width: 100% !important;
    user-select: none;
  }

  #chart {
    max-width: 100%;
    min-width: 100%;
  }

  .apexcharts-tooltip {
    left: 10% !important;
  }
}

.controlTowerRow {
  background-color: #f1f5f7;
  // height: 61px !important;
}

.controlTowerPadding {
  padding-left: 10px;
}

.tripsWhiteSpace {
  white-space: nowrap;
  box-sizing: border-box;
}

.controlAlignItem {
  padding: 0px 15px;
}

.towerInTransit {
  .dashboard-icon {
    padding-left: 62px;
  }
}

.tripHighlights {
  margin-right: 5px;
  border-radius: 5px;
}

.controlTooltipIcontext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50px;
}

.towerLoader {
  .WaveLoaderdiv {
    top: 72%;
  }
}

.ivrDiv {
  border: 1px solid #e53d2c;
  padding: 10px;
  background-color: #ffe3e1;
  margin: 10px;
}

.isChecked {
  background-color: #13121266 !important;
  border: 2px solid #5b5b5b66 !important;
}

.latestKnownMargin {
  margin-bottom: 0px !important;
}

.vehiclAnalysisRow {
  padding-right: 25px;
}

.tripCreatedFilter {
  margin-left: 24%;
  padding: 2px;
}

.orderCancelledHeight {
  min-height: 278px !important;
}

@media (min-width: 576px) {
  .as_model .modal-dialog {
    max-width: 95%;
  }
}

.button-wide {
  width: 125px;
  height: 37px;
}

.sharp-corners-right {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.sharp-corners-left {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.tile-div {
  height: 70px;
  border-radius: 2px;
}

.tile-text {
  padding-top: 26px;
  height: 80px !important;
  font-weight: 900;
  color: #ffffff;
}

.accepted-color {
  color: #9b9de4;
}

.rejected-color {
  color: #dd4306;
}

.assigned-color {
  color: #90d5f5;
}

.finalized-color {
  color: #0eda52;
}

.drafted-color {
  color: #eb8518;
}

.expired-color {
  color: #5664d2;
}

.cancelled-color {
  color: red;
}

.vertical-middle {
  vertical-align: middle !important;
}

.last-sync-color {
  color: #bcbcbc;
}

.rounded-black {
  border-radius: 4px !important;
  background-color: #333333 !important;
  color: white !important;
}

.rounded-blue {
  border-radius: 4px !important;
  background-color: #b9d9eb !important;
  color: rgb(10, 10, 10) !important;
}

.rounded-left {
  border-radius: 4px 0px 0px 4px !important;
}

.rounded-right {
  border-radius: 0px 4px 4px 0px !important;
}

.color_edit_button {
  background-color: #e4ebf3;
}

.reset-col {
  display: flex;
  height: fit-content;
}

.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tracking {
  .gm-fullscreen-control {
    left: 0px;
  }

  .btn-tracking {
    // font-weight: bold;
    line-height: 12px;
    color: whitesmoke !important;
    border: none;
    word-wrap: break-word;
    font-size: 13px;
    font-weight: 700;
    font-family: Roboto;
    width: 100%;
    height: 26px;
    padding: 5px;
  }

  .gm-menu-control {
    background: #d2d3d9;
    border: 0px;
    margin: 10px;
    padding: 0px;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    top: 0px;
    right: 0px;
    color: #5664d2 !important;
  }

  .inactive {
    background-color: #f1f5f7 !important;
    color: #05284e !important;
  }

  .active {
    background-color: #0ab38c !important;
    color: #05284e !important;
  }
}

.css-6q0nyr-Svg {
  color: "#2800FC" !important;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #0ab38c !important;
}

.dataTableStyles {
  thead {
    background-color: #505d69;
    color: white;

    line-height: 21px;
    font-size: 14px;
  }

  .table th {
    font-weight: 700 !important;
    font-family: "Roboto", sans-serif;
  }

  .table td {
    font-weight: 500 !important;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #343a40;
    vertical-align: baseline;
  }
}

.dataTableButtons {
  width: 100%;
  background-color: #5664d2;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  :active {
    color: white !important;
  }

  :hover {
    color: white !important;
  }
}

#contentCard {
  padding: 0px;
  margin-left: 85px;
  transition: margin-left 0.5s;
}

//for removing the bottom header from the dataTable
tbody+thead {
  display: none;
}

.page-content {
  font-family: "Roboto", sans-serif;
}

.searchBox {
  background-color: #f1f5f7 !important;
}

.iconSize {
  font-size: 0.7em;
}

.bar {
  list-style: none;
  text-align: initial;
}

.bar>li {
  position: relative;
}

.bar>li:before {
  content: "\25CF";
  margin-right: 10px;
  font-size: 20px;
  color: #5664d2;
}

.bar>li:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-left: 2px dotted #5664d2;
  margin-left: 5px;
  height: 100%;
}

.bar>li:first-of-type:after {
  top: 50%;
}

.bar>li:last-of-type:after {
  top: -50%;
}

//tripdetail css
.w-100 {
  width: 80% !important;
}

.q-100 {
  width: 60% !important;
  height: 60% !important;
}

.tar {
  list-style: none;
  text-align: initial;
}

.tar>li {
  position: relative;
}

.tar>li:before {
  content: "\25CF";
  margin-right: 10px;
  font-size: 20px;
  color: #5664d2;
}

.tar>li:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  // border-left: 2px dotted #5664d2;
  margin-left: 5px;
  height: 100%;
}

.tar>li:first-of-type:after {
  top: 50%;
}

.tar>li:last-of-type:after {
  top: -50%;
}

//Billing

.btn-billing {
  background-color: #5664d2 !important;
}

.col-form-label {
  font-weight: 500;
}

#printagreement {
  width: 90%;
}

.dataTableStyles1 {
  width: 90%;

  thead {
    background-color: #505d69;
    color: white;

    line-height: 21px;
    font-size: 14px;
  }

  .table tbody {
    border: 1px solid #505d69;
  }

  .table th {
    font-weight: 700 !important;
    font-family: "Roboto", sans-serif;
    border: 1px solid #505d69;
  }

  .table td {
    font-weight: 500 !important;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #343a40;
    vertical-align: baseline;
    border: 1px solid #505d69;
  }
}

///pdf

table {
  page-break-inside: auto;
}

table td,
th,
tr {
  page-break-inside: auto;
}

#printagreement {
  // margin: 1rem;
  // margin-left: 13%;
  margin: 0px 68px;

  .paddingTable {
    width: 90%;
  }
}

#invoice-pdf-modal {
  .modal-lg {
    max-width: 1600px;
  }

  .pdfTable {
    width: 85%;
    // margin-left: 10%;
  }

  #dynamictable {
    width: 100% !important;
  }
}

.excl {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.36;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #5664d2;
  border-color: #5664d2;
}

@media print {
  .dynamictable {
    width: 100% !important;
  }
}

#table-to-xls {
  width: 100%;
}

.disable {
  pointer-events: none;
}

.indentModal {
  padding: 0px;
}

// tr.MuiTableRow-root {
//   &.customDataTable_hover {
//     background-color: white;
//     :hover {
//       background-color: #0eda52;
//     }
//   }
// }
.transporterList tbody {
  tr:hover {
    background-color: #d3e4e7;
  }
}

.apexcharts-legend {
  justify-content: start !important;
}

.apexcharts-yaxis text {
  fill: black;
  font-weight: 600;
}

.apexcharts-xaxis text {
  fill: black;
  font-weight: 600;
}

.MuiButton-root {
  text-transform: capitalize !important;
}

#typeVehicle {
  width: 400px;
}

.dashboard-header-div {
  .dashboard-header {
    Card {
      margin-bottom: 0px !important;
    }
  }

  .card {
    margin-bottom: 15px !important;
    margin-top: 5px;
  }

  .Vehicle-text-align {
    text-align: left;
  }

  @media (min-width: 960px) and (max-width: 1224px) {

    .operations-dashboard-btn-operations,
    .operations-dashboard-btn-transpoter,
    .operations-dashboard-btn-invoice {
      font-size: 12px;
    }

    .operations-dashboard-btn-reset {
      width: 100%;
    }
  }

  @media (max-width: 960px) {
    .dashboard-header {
      .MuiGrid-align-items-xs-center {
        align-items: center;
        justify-content: center;
      }

      .react-select__multi-value__label {
        width: fit-content !important;
      }
    }
  }
}

.dashboard-header-invoice-div {
  .card {
    margin-bottom: 0px;
  }
}

@media (min-width: 960px) and (max-width: 1224px) {

  // .freight-cost-span-names {
  //   font-size: 11px;
  // }
  .freight-cost-span-values {
    font-size: 25px;
  }
}

@media (min-width: 1400px) {

  // .freight-cost-span-names {
  //   font-size: 15px;
  // }
  .freight-cost-span-values {
    font-size: 30px;
    flex-wrap: nowrap;
  }

  .react-select__multi-value__label {
    font-size: 15px !important;
    width: 40px !important;
  }
}

.dashboard-card-div {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.dashboard-cards-grid-div {
  @media (max-width: 960px) {
    .dashboard-cards-grid {
      padding-left: 10px !important;
      padding-right: 5px !important;
    }
  }
}

.reportQuickStyleBar {

  .react-select__value-container,
  .react-select__value-container--is-multi {
    height: 26px;
    display: contents !important;
  }

  .react-select__multi-value {
    top: 0% !important;
    padding: 0px;
    margin-top: 0px !important;
    height: 18px !important;
    // width:"30px"
  }

  .react-select__indicator,
  .react-select__dropdown-indicator {
    height: 26px;
    min-height: 26px;
    padding: 5px !important;
  }

  .react-select__multi-value__label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    width: 31px;
    font-size: 8px !important;
    padding: none !important;
    background-color: white;
    padding-left: none !important;
  }

  .react-select__multi-value__remove {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.dashboard-header-div {
  .dashboard-header {

    .transpoter-react_selector,
    .hopes-react_selector,
    .roles-react-selector {

      .react-select__value-container,
      .react-select__value-container--is-multi {
        height: 26px;
        display: contents !important;
      }

      .react-select__multi-value {
        top: 0% !important;
        padding: 0px;
        margin-top: 0px !important;
        height: 18px !important;
        // width:"30px"
      }

      .react-select__indicator,
      .react-select__dropdown-indicator {
        height: 26px;
        min-height: 26px;
        padding: 5px !important;
      }

      .react-select__multi-value__label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        width: 31px;
        font-size: 8px !important;
        padding: none !important;
        background-color: white;
        padding-left: none !important;
      }

      .react-select__multi-value__remove {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }

    .date-picker-div {
      .datepicker-selector-mobile {
        width: 100% !important;
        height: 32px;
        font-size: 10px;
        font-family: Roboto;
        font-style: none;
        text-align: center !important;
        border: 1px solid #c0c0c0;
        border-radius: 5px;

        .css-17vdz66 {
          height: 26px !important;
        }

        .MuiFormControl-root,
        .MuiTextField-root {
          height: 22.5px;
          border-color: white !important;
          border-style: none !important;
        }

        .MuiOutlinedInput-root,
        .MuiInputBase-root,
        .MuiInputBase-colorPrimary,
        .MuiInputBase-formControl {
          height: 26px !important;
          min-height: 26px;
        }

        .MuiOutlinedInput-input,
        .MuiInputBase-input {
          height: 24px !important;
          border-style: none !important;
          font-size: 12px !important;
          font-family: Roboto;
          font-style: normal;
          padding: 0px !important;
          text-align: center;
        }

        .MuiOutlinedInput-notchedOutline {
          border-style: none !important;
          padding: 10px !important;
          text-align: center !important;
        }

        #mui-2-label,
        #mui-1-label {
          display: none !important;
        }

        .MuiFormLabel-root,
        .MuiInputLabel-root {
          display: none !important;
        }

        .MuiFormControl-root,
        .MuiTextField-root {
          height: 22.5px;
          border-color: white !important;
          border-style: none !important;
        }
      }

      // .datepicker-selector-mobile:focus,
      // .datepicker-selector-mobile:hover {
      //   height: 32px !important;
      //   border: 3px solid rgb(79, 116, 238) !important;
      // }
      .date-picker-div {
        width: 100%;
      }

      .datepicker-selector {
        // width: 243px !important;
        height: 32px;
        font-size: 10px;
        font-family: Roboto;
        font-style: none;
        text-align: center !important;
        border: 1px solid #c0c0c0;
        border-radius: 5px;

        .css-17vdz66 {
          height: 26px !important;
        }

        .MuiFormControl-root,
        .MuiTextField-root {
          height: 22.5px;
          border-color: white !important;
          border-style: none !important;
        }

        .MuiOutlinedInput-root,
        .MuiInputBase-root,
        .MuiInputBase-colorPrimary,
        .MuiInputBase-formControl {
          height: 26px !important;
          min-height: 26px;
        }

        .MuiOutlinedInput-input,
        .MuiInputBase-input {
          height: 24px !important;
          border-style: none !important;
          font-size: 12px !important;
          font-family: Roboto;
          font-style: normal;
          padding: 0px !important;
          text-align: center;
        }

        .MuiOutlinedInput-notchedOutline {
          border-style: none !important;
          padding: 10px !important;
          text-align: center !important;
        }

        #mui-2-label,
        #mui-1-label {
          display: none !important;
        }

        .MuiFormLabel-root,
        .MuiInputLabel-root {
          display: none !important;
        }

        .MuiFormControl-root,
        .MuiTextField-root {
          height: 22.5px;
          border-color: white !important;
          border-style: none !important;
        }
      }

      // .datepicker-selector:focus,
      // .datepicker-selector:hover {
      //   // height: 26px !important;
      //   // border: 3px solid rgb(79, 116, 238) !important;
      // }
    }
  }
}

.dashboard-header-div {
  .dashboard-baxter-header {
    .hopes-react_selector {

      .react-select__value-container,
      .react-select__value-container--is-multi {
        height: 28px;
        display: contents !important;
      }

      .react-select__multi-value {
        top: 0% !important;
        padding: 0px;
        margin-top: 0px !important;
        height: 18px !important;
        // width:"30px"
      }

      .react-select__indicator,
      .react-select__dropdown-indicator {
        height: 26px;
        min-height: 26px;
        padding: 5px !important;
      }

      .react-select__multi-value__label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        width: 80px;
        font-size: 12px !important;
        padding: none !important;
        background-color: white;
        padding-left: none !important;
      }

      .react-select__multi-value__remove {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}

//tripDetailModels
.shipModel {
  .qualityImageBox {
    background: #0ab38c;
    position: initial;
    width: 98%;
    height: 86px;
    left: 0px;
    border-radius: 4px;
    margin-left: 0px !important;
  }

  .ShipItem {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }

  .textHeaderStyle {
    color: #05284e;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }

  .textValueStyle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #05284e;
  }

  .shipmentHeadingText {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #05284e;
  }

  .shipmentHeadingDivider {
    padding: 2vh 0px;
    margin-left: -2px;
    margin-top: 11px;
    background-color: #b9b9b9;
  }

  .textAttachedColor {
    // margin-left: 455px;
    color: white;
  }

  .collapseHeader {
    background-color: #0ab38c;
    margin-top: -16px;
    margin-right: 16px;
    margin-left: 14px;
    border: 1px solid #0ab38c;
  }

  .collapseButton {
    margin-left: -8px;
    color: white;
  }

  .borderStyle {
    padding: 13px 0px;
    margin-left: -2px;
    margin-top: 12px;
    margin-bottom: 0px;
    background-color: grey;
  }

  .vls {
    border-left: 0.5px solid #b9b9b9;
    height: 50px;
    // position: absolute;
    margin-top: -61px;
    margin-left: 312px;
    position: relative;
    top: -10px;
  }

  .vls2 {
    border-left: 0.5px solid #b9b9b9;
    height: 45px;
    // position: absolute;
    margin-top: -44px;
    margin-left: 652px;
    position: relative;
    top: -10px;
  }

  .vls3 {
    border-left: 0.5px solid #ffffff;
    height: 69px;
    // position: absolute;
    margin-top: 23px;
    margin-left: 169px;
  }

  .tableStyle {
    background: #ffffff;
    border: 0.5px solid #b9b9b9;
    box-sizing: border-box;
    box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .shipmentLrCopy {
    background: #0ab38c;
    position: relative;
    width: 97.3%;
    height: auto;
    left: -11px;
    border-radius: 4px;
    margin-left: 12px !important;
    margin-top: 20px;
  }

  .q-shipvector {
    height: 50px;
    width: 50px;
    margin-left: 3px;
    border-radius: 0px;
    // position: relative;
    display: grid;
  }

  .q-shipvector2 {
    height: 50px;
    width: 50px;
    margin-left: -62px;
    border-radius: 0px;
    // position: relative;
    display: grid;
  }

  .q-shipunloadvector {
    height: 50px;
    width: 50px;
    // margin-left: -88px;
    border-radius: 0px;
    display: grid;
  }

  .lrcopyfrontrow {
    margin-left: 26px;
    color: white;
    font-size: 15px;
  }

  .lrcopyunlodedcol {
    // margin-left: 26px;
    color: white;
    font-size: 15px;
  }
}

.tripModel {
  .qualityImageBox {
    background: #0ab38c;
    position: initial;
    width: 98%;
    height: 160px;
    left: 0px;
    border-radius: 4px;
    margin-left: 0px !important;
  }

  .q-vector {
    height: 80.28947448730469px;
    width: 80.94163513183594px;
    float: left;
    margin-left: 48px;
    border-radius: 0px;
    position: relative;
  }

  .textHeaderColor {
    // color: white;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
  }

  .qualityCheckHeader {
    margin-top: 20px;
    margin-left: -11px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #05284e;
  }

  .qualtyTruckTypeText {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #05284e;
  }

  .form-check {
    width: 100%;
    display: block;
    max-width: -webkit-fill-available;
  }
}

@media (min-width: 576px) {
  .tripModel {
    max-width: 100%;
  }
}

.shipmentBox {
  background: #a8b8b4;
  position: initial;
  width: 100%;
  height: 105px;
  left: 0px;
  border-radius: 4px;
  margin-left: 0px !important;
}

.tripHistoryModel {
  .trackPathCard {
    background: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 550px;
    margin-top: 37px;
  }

  .path-card {
    width: 100%;
    height: 429px;
    // max-height: 70%;
    overflow-y: scroll;
    overflow-x: clip;
    background: #f1f5f7;
    border-radius: 4px;
  }

  .trackHeading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    color: #05284e;
  }

  .trackValuetext {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #05284e;
  }

  .googleMapCard {
    background: #fbfbfb;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 550px;
    margin-left: -23px;
    margin-top: 37px;
  }

  .dateTextStyle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    color: #0c0c0c !important;
  }

  .addressTextStyle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    color: #0c0c0c;
  }
}

////tripDetail
//// master user
.quickStylesBar {
  .input-group>.form-control {
    height: 22px !important;
    padding-left: 5px !important;
    border-style: none !important;
  }

  .input-group>.form-control:hover,
  .input-group>.form-control:focus {
    border-style: none !important;
    // height: 22px !important;
  }

  .input-border:hover,
  .input-border:focus {
    border: 2px solid #0000ff !important;
    // height: 26px !important;
    z-index: 100;

    .input-group {
      height: 24px !important;
    }
  }

  .input-group {
    width: 95% !important;
  }

  .input-border {
    height: 26px !important;
    border: 0.5px solid #aeaeae !important;
    border-radius: 5px !important;
  }

  .input-line {
    margin-top: 3px;
    height: 20%;
    z-index: 1;
    border-left: 2px solid #0000ff;
  }

  .span-img {
    width: 15px;
    height: 12px;
    z-index: 1;
    left: 50% !important;
    cursor: pointer;
  }
}

.modal-backdrop.show {
  height: 100%;
  width: 100%;
  opacity: 0.6;
  background-color: #05284e;
}

// user form
// .user-form-div {
//   .select2-container input {
//     border: 1px solid #b9b9b9 !important;
//   }
//   .select2-container input :hover,
//   .select2-container input:focus {
//     border: 2px solid blue !important;
//     min-width: 80% !important;
//     input {
//       border-style: none !important;
//       border: 0px solid white !important;
//       element.style {
//         width: 100% !important;
//         border-style: none !important;
//       }
//     }
//   }
//   input {
//     border-style: none !important;
//     border: 0px solid white !important;
//     element.style {
//       width: 100% !important;
//       border-style: none !important;
//     }
//   }
//   #react-select-2-input,
//   #react-select-3-input,
//   #react-select-4-input,
//   #react-select-5-input  {
//     width: 100% !important;
//     border-style: none !important;
//   }

//   .select2-selection__indicator,
//   .select2-selection__dropdown-indicator {
//     .css-6q0nyr-Svg {
//       fill: #2800fc !important;
//     }
//   }
//   .select2-selection__input {
//     min-width: 80% !important;
//     input {
//       border: 0px solid white !important;
//     }
//   }
// }
// @media (max-width: 980px) {
//   .user-form-div {
//     .button-group {
//       justify-content: center !important;
//       text-align: center;
//     }
//   }
// }
.quickStylesBar,
.trip-quick-filters,
.indent-quick-filters {

  .react-select__indicator,
  .react-select__dropdown-indicator {
    padding: 4px;
  }

  .MuiPickerStaticWrapper-root {
    border: 1px solid #ddd;
    // position: absolute;
    top: 12%;
    //   left: -12px;
  }
}

.dropdown {
  .dropdown-content {
    right: 40px;
    bottom: 0px;
    position: absolute;
  }
}

.trip-quick-filters Button,
.indent-quick-filters Button {
  margin: 0px;
}

.disable-export {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.tooltipIcon {
  position: relative;
  display: inline-block;
}

.tooltipIcon .tooltipIcontext {
  visibility: hidden;
  width: 100%;
  min-width: fit-content;
  white-space: nowrap;
  background-color: #05284e;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 999;
  bottom: 150%;
  left: 80%;
  margin-left: -60px;
}

.tooltipIcon .tooltipIcontextControl {
  visibility: hidden;
  min-width: fit-content;
  white-space: initial;
  flex-wrap: wrap;
  background-color: #05284e;
  height: auto;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: 70%;
  left: -230%;
  margin-left: -60px;
  width: 348px;
}

.tooltipIcon .tooltipIcontext1 {
  visibility: hidden;
  width: 70px;
  background-color: #05284e;
  color: #fff;
  text-align: center;
  font-size: 14px;
  width: max-content;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: 31%;
  left: 7%;
  margin-left: -60px;
}

.tooltipIcon .tooltipIcontext2 {
  visibility: hidden;
  width: 20 vw !important;
  background-color: #05284e;
  color: #fff;
  text-align: start;
  font-size: 14px;
  width: max-content;
  border-radius: 6px;
  padding: 5px 0;
  padding-left: 20px;
  position: absolute;
  z-index: 99;
  bottom: -103px;
  left: -106%;
  margin-left: -60px;
  // height: 300px;
  // overflow-y: auto;
}

.tooltipIcon .tooltipIcontext2::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10%;
  margin-right: -5px;
  border-width: 20px;
  border-style: solid;
  border-color: transparent transparent transparent #05284e;
}

.tooltipIcon .tooltipIcontext1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #05284e transparent transparent transparent;
}

.tooltipIcon .tooltipIcontext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #05284e transparent transparent transparent;
}

.tooltipIcon .tooltipIcontextControl::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #05284e transparent transparent transparent;
}

.tooltipIcon:hover .tooltipIcontext1 {
  visibility: visible;
}

.tooltipIcon:hover .tooltipIcontext2 {
  visibility: visible;
}

.tooltipIcon:hover .tooltipIcontextControl {
  visibility: visible;
}

.agreement-filters {
  .MuiPickerStaticWrapper-root {
    top: 13% !important;
    right: 12%;
  }
}

.tooltipIconComment {
  position: relative;
  display: inline-block;
}

.tooltipIconComment .tooltipIcontext {
  visibility: hidden;
  min-width: 200px;
  min-width: fit-content;
  white-space: wrap;
  background-color: #05284e;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 999;
  bottom: 150%;
  // left: 80%;
  // margin-left: -60px;
}

.tooltipIconComment .tooltipIcontext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  // margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #05284e transparent transparent transparent;
}

.tooltipIconComment:hover .tooltipIcontext {
  visibility: visible;
}

.ellipsis-text {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-text-remark {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip2 {
  position: relative;
  display: inline-block;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip2 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
}

.indent-quick-filters {
  .delayStyle {
    background-color: #ffe3e1;
    color: #e53d2c !important;
  }

  .activeStyle {
    background-color: red;
    color: white !important;
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.88);
  }
}

.trip-quick-filters {
  .activeTripDelayed {
    background-color: red;
    color: white !important;
    box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.7);
  }

  .activeTripUntrack {
    background-color: red;
    color: white !important;
    box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.7);
  }
}

.mobile-toggle {
  display: none;
}

.dashboard-header-div {
  .grid-align-mobile {
    display: none;
  }
}

.CardBordersDashboard {
  border: 1px solid rgb(212, 212, 212);
}

.shipment-card-parent {
  height: 320px;
  border: 1px solid rgb(212, 212, 212);

  .shortage-shipment-card {
    .shipcard-percentage {
      margin-top: 1px;
    }
  }
}

.operational-Tat-parent {
  height: 320px;
  border: 1px solid rgb(212, 212, 212);

  .operational-Tat-card {
    margin-left: 20px !important;
  }
}

.indent-details-parent,
.onTimeDelivery {
  height: 250px;
  border: 1px solid rgb(212, 212, 212);
}

.onTimeDelivery {
  .onTimeDeliveryDetails {
    width: 100%;
    height: 148px;
    float: left;
    border: 1px solid #f1f5f7;
    padding-top: 10px;
  }
}

.login-page-mobile-div {
  display: none;
}

.dashboard-header {
  padding: 0px 15px;
}

.operational-label-div {
  white-space: break-spaces;
  height: 157px;
  padding-top: 40px !important;
  width: 197px;
  padding-left: 43px;
  display: block;
}

.OperationalTatLabelsMobileDiv {
  display: none !important;
}

.textEcllipse {
  display: none;
}

.desktopTrans {
  display: block;
  cursor: pointer;
}

.invoiceDashboardDiv {
  .dashboard-header-invoice-div {
    .mobile-acceptance {
      display: flex;

      .imgInvoiceCol1 {
        left: 38%;
        top: 16%;
      }

      .InvoiceCol2 {
        left: 33%;
        top: 25%;
        text-align: center;
        padding: 15px 0px;

        .InvoiceCol2Child {
          color: rgb(5, 40, 78);
          white-space: break-spaces;
          width: 50px;
          flex-wrap: wrap;
          display: flex;
        }
      }

      .InvoiceCol3 {
        // left: 30%;
        top: 30%;
      }
    }

    .desktop-acceptance {
      display: flex;
    }

    .Pending-card {
      width: 100%;
      height: 250px;
    }
  }

  .mobile-freight {
    display: none;
  }

  .desktop-freight {
    display: flex;
  }
}

.opeartionalTAtLabels {
  padding: 0px 10px !important;
  margin-bottom: 15px;
}

.trip-card-div {
  height: 580px;
  border: 1px solid rgb(212, 212, 212);
}

.gridContainer {
  .select2-selection__control {
    width: 90%;
  }

  .dateTimeInputParent {
    width: 90%;
  }

  .indentFeildPd {
    padding-right: 25px !important;
  }

  .rdtOpen .rdtPicker {
    display: block;
    // left: 106%;
    top: -462%;
    left: 85%;
  }
}

.navbar-header-new {
  .mobileAddIndent {
    display: none;
  }

  .desktopAddIndent {
    display: contents;
  }
}

.transpoter-labels {
  width: 100%;
  font-size: 10px !important;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.tooltiptextTranspoter {
  display: none;
}

.apexChart-card {
  .apexcharts-tooltip {
    top: 50% !important;
    font-size: 8px;
    width: 250px !important;
    justify-content: center;
    cursor: pointer !important;
    white-space: break-spaces !important;
    flex-wrap: wrap;
  }
}

.IndentApexchartParent {
  .apexcharts-tooltip {
    top: 24% !important;
    font-size: 8px;
    width: 70% !important;
    justify-content: center;
    cursor: pointer !important;
    white-space: break-spaces !important;
    flex-wrap: wrap;
    left: 3% !important;
  }
}

.TripApexChartParent {
  .apexcharts-tooltip {
    top: 8% !important;
    font-size: 8px;
    width: 31% !important;
    justify-content: center;
    cursor: pointer !important;
    white-space: break-spaces !important;
    flex-wrap: wrap;
    left: 3% !important;
  }
}

// .gmnoprint {
//   .gm-style-mtc {
//     &:first-child {
//       margin-left: 30px;
//       padding-left: 10px;
//     }
//   }
// }
.gmnoprint {
  .gm-style-mtc {
    display: none !important;
  }
}

.tracking .gm-fullscreen-control {
  display: none;
}

.paginationRow {
  padding: 0px 32px;

  .spanPagenationentries {
    font-size: 13px;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
  }

  .span-col-pagenation {
    padding: 0px 0px !important;

    .desktop-pagination {
      display: flex;
    }

    .mobile-pagination {
      display: none;
    }
  }
}

.desktopReportAnalysis {
  display: flex;
}

.mobileReportAnalysis {
  display: none;
}

.reportQuickStyleBar {
  .desktopResetButton {
    display: flex;
  }

  .mobileResetButton {
    display: none;
  }

  .reportPadding {
    padding-right: 50px;
  }
}

.quickStylesBar {
  .desktopLanelot {
    display: contents;
  }

  .mobileLanelot {
    display: none;
  }

  .roboto_family {
    font-size: 13px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
  }
}

.driverMobileCol {
  .flex-row {
    margin-left: -22px;
  }
}

.vehicleStyle {
  .justify-content-start {
    margin-left: -22px;
  }
}

.agreement-filters {
  .agreementDesktopRefresh {
    display: flex;
  }

  .agreementMobileRefresh {
    display: none;
  }

  // .flex-row-end{
  //   margin-left: -8px;
  // }
}

.BillDetailsFont {
  font-family: Roboto;
  font-style: normal;
  font-size: 13px;
  font-weight: 400;
  color: #252b3b;
}

.languageActive {
  color: #16181b;
  text-decoration: none;
  background-color: red;
}

.languageActive:hover {
  color: #16181b;
  text-decoration: none;
  background-color: red;
}

.addIndentDateDiv {
  width: 100%;
}

.driverSaveBtn {
  position: relative;
  left: 85%;
}

.marginSaveDiv {
  margin-bottom: 17px;
}

.marginSaveDiv button {
  margin-top: 0px;
}

.vehicleChangeIncident {
  font-size: 16px;
}

.driverSaveBtnDiv {
  position: relative;
  left: 87.5%;
}

.vehicleSaveBtnDiv {
  position: relative;
  left: 86.7%;
}

.tripsDetailsModal {
  .modal-content {
    .modal-head {
      button {
        display: none;
      }
    }
  }
}

.tripModel {
  .modal-content {
    padding: 25px;
  }
}

.deductionRedDiv {
  color: red !important;
}

.textALign {
  padding-left: 37%;
  text-align: start;
}

.offlineMode {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  font-family: Roboto;
}

.navigatorOfflineDiv {
  width: 100%;
  height: 30px;
  background-color: #ff0000;
  color: #ffffff;
}

.navigatorOfflineInnerDiv {
  color: #ffffff;
  font-family: Roboto;
  font-weight: 700;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
}

.navigatorOnlineDiv {
  width: 100%;
  height: 30px;
  background-color: green;
}

.navigatorOnlineInnerDiv {
  color: #ffffff;
  font-family: Roboto;
  font-weight: 700;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
}

// .textALignUser{
//   padding-left: 35%;
//   text-align: start;
// }
.FieldsClass {
  .rs-picker-toggle {
    height: 22px;
  }
}

.dateSearch {
  .css-17vdz66 {
    border: 0.2px solid #e7d9d9;
    width: 100%;

    .MuiBox-root {
      // margin-left: 5px;
      padding-left: 5px !important;
    }
  }

  .rs-picker-toggle-wrapper:hover {
    border: none !important;
    padding: 0px !important;
  }

  .rs-picker-toggle:hover {
    padding: 0px !important;
    border: 0.5px solid hsl(0, 0%, 70%) !important;
  }
}

@media (max-width: 959px) {
  .shipment-card-parent {
    height: auto;
    padding-bottom: 20px;
    margin-top: 5px;

    .MuiGrid-spacing-xs-2>.MuiGrid-item {
      padding: 0px !important;
    }

    button,
    input {
      width: 50% !important;
      border: none;
      border-style: none !important;
      overflow: visible;
      font-size: 10px;
      font-family: Roboto;
      font-style: normal;
      padding-left: 14px !important;
      // margin-left: -3px !important;
      // left: 0px;
    }

    .Mui-focused {
      border: none;
    }

    input,
    button,
    select,
    optgroup,
    textarea {
      ::before {
        padding-left: 0% !important;
      }

      ::after {
        box-sizing: none !important;
        padding-left: 6% !important;
      }
    }

    input,
    button,
    select,
    optgroup {
      border: 0.5px solid white !important;

      ::after {
        border: 0.5px solid white !important;
      }
    }

    input[type="tel" i] {
      padding: 2px 2px;

      // border-style: none !important;
      // border: 0.1 solid white !important;
      ::after {
        border-style: none !important;
      }
    }
  }
}

.RenderInput {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.freightCostApexchart {
  width: 100%;
}

.costRevenuPadding {
  right: 1%;
}

.desktop-reset {
  display: inline;
}

.mobile-reset {
  display: none;
}

.desktopDisplayShipment {
  display: block;
}

.desktopDisplay {
  display: flex;
}

.desktopDisplayaddIndent {
  display: block;
}

.mobileDisplayCards {
  display: none;
}

.mobileDisplayShpment {
  display: none;
}

.mobileDisplay {
  display: none;
}

.modal {
  padding: 8px;
  top: 1%;
}

.modal-dialog {
  top: 1%;
}

// .modal-open .modal{
//   top: 1%;
// }
.btnExpense {
  height: 26px;
  line-height: 16px;
  margin-top: 29px;
  padding: 5px;
}

.closemodal2 {
  position: absolute;
  top: 1%;
  left: 95%;
  cursor: pointer;
  font-size: 20px;
}

.closemodalLog {
  position: absolute;
  top: 1%;
  left: 90%;
  cursor: pointer;
  font-size: 20px;
}

.closemodal1 {
  position: absolute;
  top: 23%;
  left: 92%;
  cursor: pointer;
  font-size: 20px;
}

.roadMobileImg {
  width: 139%;
}

.operationalTatApexChart {
  .apexcharts-tooltip {
    top: 0px !important;
    height: 133px;
    margin: 10px 0px;
  }
}

.raiseLine {
  line-height: 7;
}

.addAgreementCard {
  background-color: #ffffff;
  margin-bottom: 8px !important;
  height: 57px;
}

.addAgreementCardBottom {
  background-color: #ffffff;
  margin-bottom: 8px !important;
  height: 69px;
}

.span-names-agreement {
  display: flex;
  left: 2%;
  padding-top: 15px;
  position: relative;
}

.ModalHeight {
  height: 700px;
  display: flex;

  .modal-content {
    padding: 25px;
  }

  .select2-selection__control {
    height: 30px;
  }

  .select2-selection__indicators {
    background-color: #ffff;
    height: 30px;

    .css-6q0nyr-Svg {
      fill: #726060;
    }
  }

  .select2-selection__indicator-separator {
    padding-top: 23px;
  }

  .select2-container .select2-selection__indicator {
    margin-top: 6px;
  }
}

.EditUserModal {
  .select2-selection__indicators {
    background-color: #ffff;

    .css-6q0nyr-Svg {
      fill: #726060;
    }
  }
}

.select2-selection__indicator-separator {
  padding-top: 30px;
}

.select2-container .select2-selection__indicator {
  margin-top: 6px;
}

.vehicleTypeForm {
  .labelStyle {
    color: #05284e;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
  }

  .inputVtStyle {
    height: 40px;
    width: 420px;
    background-color: #ffffff;
    border: 0.5px solid #b9b9b9;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .button-wide-vType {
    height: 40px;
    width: 90px;
    top: 549px;
    border-radius: 0px;
    background-color: #05284e;
  }
}

.DataTable {
  padding-top: 16px;
  height: 493px;
  overflow: auto;
}

.DataTableSub {
  padding-top: 16px;
  height: 700px;
  overflow: auto;
  margin-bottom: 15px;
}

.buttomClr {
  width: 100%;
  background-color: #0ab38c !important;
}

.buttonClrS {
  background-color: #05284e !important;
}

.quickstyleAddagreement {
  .form-control-sm {
    width: 100%;
  }

  .select2-container {
    height: 30px;

    .rdt {
      height: 30px;

      input {
        height: 27px !important;
        background-color: #ffff;
        text-overflow: ellipsis;
        width: 250px;
        font-size: 13px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
      }
    }

    .SearchAddAgre {
      position: relative;
      left: -35px;
      padding-top: 5px;
    }
  }

  // .TranspoterWid{
  //  width: 1000px;
  // }
  .padding5 {
    padding-top: 5px;
  }

  .MobilePadding {
    padding-left: 3px;
    padding-right: 15px;
  }

  .MobilePaddingR {
    padding-right: 0px;
  }

  .icon-cont {
    width: 53px;

    span {
      border-radius: 20%;
      background-color: #e1e2e1;
      display: inline-block;
      height: 30px;
      position: relative;
      left: -11px;
    }
  }

  .rdtPicker {
    margin-top: 31px;
  }
}

.pdl15 {
  padding-left: 15px;
}

.dashboard-header {
  background-color: #fff;
  height: auto;
  width: 100%;
}

.OperationaExport {
  position: relative;
  right: 16px;
}

.EditUserModal {
  min-width: 900px;

  .modal-content {
    padding: 23px;
  }

  .form-group {
    .form-control {
      height: 50px;
      padding: 12px;
      font-size: 13px;
      font-family: Roboto;
      font-weight: 400;
    }
  }

  .UserCard {
    height: 250px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid #efe7e7;
  }

  .closemodalUser {
    font-size: 20px;
    color: #2800fc;
  }

  .modal-header {
    border: none !important;
  }
}

.AprovalModal {
  height: 513px;

  .form-control::placeholder {
    position: relative;
    left: -20px;
  }

  .modal-footer {
    border: none !important;
  }

  .modal-content {
    padding: 23px;
  }

  .select2-selection__indicators {
    background-color: #ffff;

    .css-6q0nyr-Svg {
      fill: #2800fc;
    }
  }

  .select2-selection__indicator-separator {
    padding-top: 34px !important;
  }

  .select2-selection__indicator {
    margin-top: 6px;
  }

  .select2-selection1__indicators {
    background-color: #ffff;

    .css-6q0nyr-Svg {
      fill: #2800fc;
    }
  }

  .select2-selection1__indicator-separator {
    padding-top: 28px !important;
  }

  .select2-selection1 __indicator {
    margin-top: 6px;
  }

  .AddNewSpan {
    width: 100%;
    background-color: #f1f5f7;
    height: 32px;
    padding: 5px;
  }

  .AddNewSpanIcon {
    position: relative;
    left: -16px;
    font-size: 14px;
    color: #2800fc;
  }

  .closemodalAproval {
    position: relative;
    left: -26%;
    font-size: 16px;
    top: -13%;
    color: #0000ff;
  }

  .modal-header {
    border: none !important;
  }
}

.saveButtonPadding {
  position: relative;
  left: -13px;
}

.TripCancelModal {
  min-width: 699px;

  .modal-content {
    padding: 23px;
  }

  .md-textarea {
    height: 238px;
  }

  .close {
    font-size: 27px;
    color: #2800fc;
    font-weight: 700;
  }

  .modal-header {
    border: none !important;
  }
}

.VerifyShipmentModal {
  min-width: 800px;

  .greenBorder {
    padding: 7px;
    min-width: 187%;
    background-color: #a5f2bb;
    /* height: 20px; */
    min-height: 30px;
    border: 1px solid green;
    margin-bottom: 10px;
  }

  .InputForm {
    height: 38px;
    min-width: 100%;
    background-color: #f1f5f7;
    border: none;
    border-bottom: 1px solid #b9b9b9;
  }

  .SvgIcon {
    width: 13px;
    position: relative;
    left: -56px;
    top: 11px;
    height: 13px;
    margin-left: 28px;
  }

  .AddNUmberBtn {
    background-color: #05284e;
  }

  .modal-header {
    border: none !important;
  }

  .modal-content {
    padding: 23px;
  }

  .PfShipmentDiv {
    left: -24px;
    width: 103%;
    height: auto;
    background-color: #f1f5f7;
    padding: 20px;
    margin-right: 10px;
    position: relative;

    .form-control::placeholder {
      position: relative;
      left: -15px;
    }
  }

  .DriverConsentDiv {
    border: 1px solid #e53d2c;
    padding: 10px;
    background-color: #ffe3e1;
    width: 96%;
  }
}

.ReportIncidentModal {
  min-width: 667px;

  .modal-content {
    padding: 23px;
  }

  .select2-selection__indicators {
    background-color: #ffff;

    .css-6q0nyr-Svg {
      fill: #2800fc;
    }
  }

  .select2-selection__indicator-separator {
    padding-top: 30px;
  }

  .select2-container .select2-selection__indicator {
    margin-top: 6px;
  }

  textarea.form-control {
    height: 238px;
  }

  .AddNewSpan {
    width: 100%;
    background-color: #f1f5f7;
    height: 32px;
    padding: 5px;
  }

  .AddNewSpanIcon {
    position: relative;
    left: -3px;
    font-size: 14px;
    color: #2800fc;
  }

  .closemodal {
    position: absolute;
    top: 5%;
    left: 89%;
    cursor: pointer;
    font-size: 20px;
  }

  .modal-header {
    border: none !important;
  }
}

.TranspoterModal {
  min-width: 474px;

  .form-control::placeholder {
    text-align: left;
    padding-top: 0px;
    position: relative;
    left: -20px;
  }

  .modal-content {
    padding: 23px;
  }

  .modal-header {
    border: none !important;
  }

  .select2-selection__indicators {
    background-color: #ffff;

    .css-6q0nyr-Svg {
      fill: #2800fc;
    }
  }

  .select2-selection__indicator-separator {
    padding-top: 30px;
  }

  .select2-container .select2-selection__indicator {
    margin-top: 6px;
  }

  .AddNewSpan {
    width: 100%;
    background-color: #f1f5f7;
    height: 32px;
    padding: 5px;
  }

  .AddNewSpanIcon {
    position: relative;
    left: -15px;
    font-size: 16px;
    color: #2800fc;
  }

  .closemodal {
    position: absolute;
    top: 7%;
    left: 86%;
    cursor: pointer;
    font-size: 20px;
  }
}

.CardDetails {
  width: 100% !important;
  height: 619px;
  position: relative;
  background-color: #ffffff;
  top: 9%;
  left: 26px;
  padding: 23px;
}

.btn-tracking:active {
  background-color: #0ab38c !important;
}

.rs-picker-daterange-header,
.rs-calendar-header,
.rs-calendar-header,
.rs-btn-icon,
.rs-btn,
.rs-calendar-table {
  color: #0c0c0c !important;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
}

.rs-calendar-table-header-row {
  .rs-calendar-table-cell {
    color: #0c0c0c !important;

    .rs-calendar-table-cell-content {
      color: #0c0c0c !important;
      font-size: 12px;
      font-family: Roboto;
      font-weight: 400;
    }
  }
}

.rs-calendar-table-row {
  .rs-calendar-table- {
    .rs-calendar-table-cell-content {
      color: #0c0c0c !important;
    }
  }
}

.RsuiteDateRange {
  .rs-picker:hover {
    border: none !important;
    height: 26px !important;
  }
}

.QuickFilters {
  .datepicker-custom {
    position: relative;
    top: 0px;
    right: 2px;
    height: 26px;
    width: 30px;
    background-color: white !important;
    border-radius: 0.25rem !important;
  }

  .datepicker-custom :hover {
    background-color: #636678 !important;
  }

  .rs-picker-toggle-wrapper {
    padding: 0px !important;
    height: 26px !important;
    width: 30px !important;
    background-color: white !important;
    border: none !important;
    border-radius: 0.25rem !important;
  }

  .rs-picker-toggle :hover {
    background-color: #636678 !important;
  }

  .rs-picker-toggle :active {
    border: 1px solid transparent !important;
    border-color: #74788d !important;
    border-radius: 0.25rem !important;
  }

  .rs-picker-toggle :focus {
    border: 1px solid transparent !important;
    border-color: #74788d !important;
    border-radius: 0.25rem !important;
  }

  .rs-picker-toggle {
    padding: 0px !important;
    height: 26px !important;
    width: 30px !important;
    background-color: #f1f5f7 !important;
    border: 1px solid transparent !important;
    border-color: #74788d !important;
    border-radius: 0.25rem !important;
  }

  .rs-picker-toggle-textbox {
    display: none !important;
  }

  .rs-picker-toggle-value {
    display: none !important;
  }

  .rs-picker-toggle-clean {
    display: none !important;
  }

  img {
    position: relative !important;
    right: 6px !important;
    top: 1px !important;
  }

  .invoiceListStyle {
    right: -5px !important;
  }

  .reportsListStyle {
    right: -5px !important;
  }

  .invoiceListDatePicker {
    top: 8px !important;
  }

  // input {
  //   display: none;
  // }

  // .datepicker-selector-mobile *:focus {
  //   box-shadow: none !important;
  //   outline: none !important;
  // }
}

reportQuickFilters {
  img {
    right: -5px !important;
  }
}

.CanelIndentModal {
  min-width: 699px;

  textarea.form-control {
    height: 233px;
    font-size: 15px;
  }

  .modal-content {
    padding: 23px;
  }

  .CancelIndentCloseIcon {
    position: absolute;
    top: 49px;
    left: 89%;
  }

  .modal-header {
    border: none !important;
  }
}

.RaiseInvoiceModal {
  .closemodal {
    position: absolute;
    top: 6%;
    left: 86%;
    cursor: pointer;
    font-size: 20px;
  }

  .modal-content {
    padding: 23px;
  }

  .invoiceinput input {
    background-color: #ffffff;
    height: 50px;
  }

  .raiseLine {
    line-height: 2;
  }

  .rdt .form-control {
    height: 50px;
    background-color: #ffffff;
  }

  .MuiButton-label {
    font-size: 24px;
  }
}

.darkModeInvoiceModal {
  .rdtPicker {
    background-color: #171722 !important;
    color: #b7a9ff !important;
  }

  .rdtSwitch {
    color: #b7a9ff !important;
  }
}

.RejectIndentModal {
  min-width: 522px;

  .modal-content {
    padding: 23px;
  }

  .closemodal {
    position: absolute;
    top: 37px;
    left: 88%;
    cursor: pointer;
    font-size: 20px;
  }
}

.pdL {
  padding-left: 37px !important;
}

.IndentDetailModal {
  height: 700px;

  .closeIcon {
    position: relative;
    color: rgb(40, 0, 252);
    width: 20px;
    margin-right: 0px;
    left: -60px;
  }

  .rejectionHistoryBox {
    min-height: 130px;
    width: 89%;
    background-color: rgb(255, 227, 225);
    border-radius: 5px;
    margin: 4px auto auto;
    position: relative;
    left: -25px;
  }
}

.TrackingPage {
  position: relative;
  min-height: 100vh;

  .gmnoprint {
    .gm-style-mtc {
      display: none !important;
    }
  }

  .gm-fullscreen-control {
    display: none;
  }

  .DashedLine {
    border-left: 3px dashed blue;
    height: 100%;
    position: relative;
    left: 5px;
  }

  .iconSize {
    font-size: 15px;
  }

  .spanToggle1 {
    position: absolute;
    top: 10%;
    left: 10px;
    cursor: pointer;
  }

  .spanToggle2 {
    position: relative;
    top: 14%;
    left: 107%;
    cursor: pointer;
  }
}

.SkipShipmentModal {
  .modal-content {
    padding: 23px;
  }

  .close {
    font-size: 30px;
  }

  .modal-header {
    border-bottom: none !important;
  }

  .form-control::placeholder {
    text-align: left;
    padding-top: 0px;
    position: relative;
    top: -45%;
    left: -20px;
  }
}

.CarbonFootPrintDiv,
.FreightCostDiv,
.costTorevenuRatio,
.operational-Tat-parent {
  .select2-selection__indicators {
    background-color: #ffff;

    .css-6q0nyr-Svg {
      fill: #726060;
    }
  }

  .select2-selection__indicator-separator {
    padding-top: 30px;
  }

  .select2-container .select2-selection__indicator {
    margin-top: 6px;
  }

  .css-2b097c-container {
    width: 100px;
  }

  .react-select__dropdown-indicator {
    padding: 0px !important;
    padding-top: 5px !important;
    color: #2800fc;
  }
}

.TranspoterModel {
  .modal-content {
    padding: 25px;
  }

  .modal-header {
    border-bottom: none !important;
  }

  .closemodal1 {
    position: absolute;
    top: 23%;
    left: 94%;
    cursor: pointer;
    font-size: 20px;
  }
}

.card-cancelled {
  margin-top: -0px;
}

.card-expiry {
  margin-top: -7px;
}

.CarbonFootPrintDiv {
  padding: 0px 15px;
}

.check-field {
  width: 42px !important;
  height: 18px !important;
  position: relative !important;
  top: 4px !important;
  left: -23px !important;
  justify-content: center;
  display: flex;
}

.verifyFade {
  opacity: 0.5;
  background-color: #05284e;
}

.shipModel {
  .modal-content {
    padding: 25px;
  }

  .modal-body {
    max-height: calc(100vh - 42px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .paddingL18 {
    padding-left: 18px;
  }
}

.DialogBox {
  .MuiTypography-h6 {
    position: relative;
    left: -6px;
  }

  .form-control::placeholder {
    position: relative;
    left: -20px;
  }

  .MuiBackdrop-root {
    opacity: 0.9;
    background-color: #05284e !important;
  }

  .makeStyles-dialogWrapper-1 {
    padding: 25px !important;
  }
}

.VehicleTypeButton {
  background-color: rgb(241, 245, 247);
  color: #05284e !important;
  width: 150px;
}

.LoadGridHeight {
  height: auto;

  .LoadAddItem,
  .LoadAddContainer {
    width: 100%;
    height: 860px;
    margin-bottom: 43px;
    background-color: #ffffff;
    padding: 25px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.129);
  }

  .spanAddItemName {
    margin-top: 20px;
    margin-left: 20px;
  }

  .addItem-hr-line {
    margin-top: 15px;
    border-top: 1px solid #b9b9b9;
    width: 94%;
    position: relative;
  }

  .Container-hr-line {
    margin-top: 15px;
    border-top: 1px solid #b9b9b9;
    width: 97%;
    position: relative;
  }

  .vehicleTypeCard {
    margin-top: 16px;
    height: 223px;
    width: 100%;
    background-color: #f1f5f7;

    .select2-selection1__indicators {
      background-color: #ffff;

      .css-6q0nyr-Svg {
        fill: #2800fc;
        margin-top: 7px;
      }
    }

    .select2-selection1__indicator-separator {
      padding-top: 22px !important;
    }

    .select2-selection1 __indicator {
      margin-top: 6px;
    }
  }

  .rowPadding {
    padding: 10px 25px;
  }

  .rowPadding1 {
    padding-left: 22px;
  }

  .AddNUmberBtn {
    position: relative;
    top: 37px;
    width: 100%;
    text-align: center;
    background-color: #05284e;
  }

  .RunStimulationButton {
    height: 50px;
    background-color: #05284e;
  }

  .optimizerCard {
    width: 97.5%;
    height: 450px;
    background-color: #ffffff;
    margin-left: 16px;
    text-align: center;
    display: grid;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .ItemDetailsCard {
    background-color: #f1f5f7;
    height: 80px;
    padding: 8px;
  }

  .itemdetailsRowPadding {
    padding: 10px;
  }

  .itemDetailsDiv {
    height: 370px;
    overflow: auto;
  }

  // .react-select1__indicators{
  //   display: none;
  // }
  .InputForm {
    height: 15px;
    min-width: 100%;
    background-color: #f1f5f7;
    border: none;
    border-bottom: 1px solid #b9b9b9;
  }

  .SvgIcon {
    width: 12px;
    position: relative;
    left: 90%;
    top: -21px;
    height: 12px;
    margin-left: 28px;
  }

  .typeScroll {
    height: 108px;
    overflow-y: scroll;
  }

  .ptdb10 {
    padding-bottom: 10px !important;
  }

  .addbox {
    .react-select1__control {
      height: 30px;
      min-height: 26px !important;
    }

    .react-select1__indicator {
      margin-top: -4px;
    }

    .react-select1__indicator-separator {
      margin-bottom: 12px !important;
    }

    .css-2b097c-container {
      width: 100px;
    }
  }

  .ptd5 {
    padding-top: 8px;
  }

  .btn-group {
    border: 2px solid #cadae2 !important;
    border-radius: 4px;
    height: 40px;
    min-width: 97%;
  }

  .PcsInput {
    text-align: center;
  }

  .DownArrow {
    position: absolute;
    top: 8px;
    margin-left: 7px;
  }

  .upArrow {
    position: absolute;
    top: 8px;
    right: 57px;
  }

  .paddingContainer {
    padding: 12px 25px 7px 17px;
  }
}

.LoadGridHeight {
  .select2-selection__indicators {
    background-color: #ffff;

    .css-6q0nyr-Svg {
      fill: #2800fc;
    }
  }

  .select2-selection__indicator-separator {
    padding-top: 21px;
  }

  .select2-container .select2-selection__indicator {
    margin-top: 6px;
  }
}

.tile-text1 {
  padding-top: 26px;
  height: 70px !important;
  font-weight: 400;
  color: #0c0c0c;
}

.hrLineProfile {
  width: 70%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #b9b9b9;
}

.CarbonfootPrintParent {
  width: 100%;
  height: 474px;
  border: 1px solid rgb(212, 212, 212);
}

// ******* mobile break points ****

@media (min-width: 1280px) {
  .datePickerGrid {
    min-width: 18%;
  }
}

@media (max-width: 1279px) {
  .datePickerGrid {
    min-width: 24%;
  }

  .onTimeDeliveryQuickFilter {
    .quickStylesBar {
      width: 90%;
    }
  }
}

@media (max-width: 1125px) {
  .onTimeDeliveryQuickFilter {
    .quickStylesBar {
      width: 85%;
    }
  }
}

@media (max-width: 989px) {
  #printagreement {
    margin-left: 5%;
  }

  .modal-content {
    padding: 23px;
  }
}

@media (max-width: 959px) {
  .onTimeDeliveryQuickFilter {
    .quickStylesBar {
      width: 80%;
    }
  }

  .shipment-card-parent {
    height: auto;
    padding-bottom: 20px;
    margin-top: 5px;

    .MuiGrid-spacing-xs-2>.MuiGrid-item {
      padding: 0px !important;
    }

    .MuiGrid-root>.MuiGrid-item {
      padding-left: 0px !important;
      padding-top: 0px !important;
    }

    .shortage-shipment-card,
    .shipment-card {
      width: 90%;
      height: 178px;
      // .shipment-card-value {
      //   font-size: 20px !important;
      // }
    }

    .shipment-card {
      margin-left: 15px;
    }

    .shortage-shipment-card {

      // padding-left: 20px;
      // margin-left: 10px;
      .shipcard-percentage {
        margin-top: 2px !important;
      }
    }
  }
}

@media (max-width: 889px) {
  .onTimeDeliveryQuickFilter {
    .quickStylesBar {
      width: 75%;
    }
  }

  .controlTowerMobileHeader {
    padding-left: 0px !important;
    padding-right: 30px;
  }

  .paginationRow {
    padding: unset;

    .spanPagenationentries {
      font-size: 13px;
    }

    .span-col-pagenation {
      padding: 0px 0px !important;

      .desktop-pagination {
        display: none;
      }

      .mobile-pagination {
        display: flex;
      }
    }
  }

  .gridContainer {
    .indentFeildPd {
      padding-right: 0px !important;
    }

    .mobileIndentImg {
      display: none;
      object-fit: contain;
    }

    .select2-selection__control {
      width: 90%;
    }

    .dateTimeInputParent {
      width: 90%;
    }
  }
}

@media (max-width: 768px) {
  .operational-Tat-parent {
    height: auto;

    .operational-label-div {
      height: auto;
      padding-bottom: 15px;
    }

    .operational-Tat-card {
      margin-left: 15px;
    }

    .mobile-operational-tat-labels {
      width: 100%;
      min-width: 100%;
      display: flex;
      white-space: initial;
      flex-grow: 1;
      flex-direction: row !important;
      flex-shrink: initial !important;
    }

    .span-operationalTat-cirlce1 {
      top: 5px;
    }
  }
}

@media (max-width: 640px) {
  .LoadGridHeight {
    .LoadAddContainer {
      height: 1000px;
    }
  }

  .shipModel {
    .paddingL18 {
      padding-left: unset;
    }
  }

  .CarbonFootPrintDiv {
    padding: unset;
  }

  .IndentDetailModal {
    height: 720px;

    .closeIcon {
      left: -33px;
    }

    .rejectionHistoryBox {
      min-height: 150px;
      width: 91%;
      left: -12px;
    }
  }

  .pdL {
    padding-left: 30px !important;
  }

  .pad {
    padding: 20px;
  }

  .MobilefontRoboto127 {
    font-family: Roboto;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
  }

  .MobilefontRoboto124 {
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
  }

  // .dashboard-header-div{
  .rs-picker-menu {
    left: 31% !important;
    top: 9% !important;
  }

  // }
  .CardDetails {
    width: 89%;
  }

  .mobileScroll {
    height: 100px !important;
  }

  .IndentDetailsCardDiv {
    margin-bottom: 10px !important;
  }

  .OperationaExport {
    position: relative;
    right: 22px;
  }

  .MobileShipCardPad1 {
    position: relative;
    right: 16px;
  }

  .MobileShipmentPad {
    padding-left: 20px;
    padding-right: 10px;
  }

  .imageIconS {
    padding-left: 0%;
  }

  .spanPagePadding {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    font-size: 14px;
  }

  .pd10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobilepPadding {
    padding-left: 30px;
    padding-right: 30px;
  }

  // .mobileIndentRow{
  //   position: relative;
  //   right: -15%;
  // }
  .operations-dashboard-btn-operations,
  .operations-dashboard-btn-transpoter,
  .operations-dashboard-btn-invoice {
    font-size: 12px;
    height: 34px;
  }

  .mgt5 {
    margin-top: 10px;
  }

  .dashboard-header {
    width: 106% !important;
    position: relative;
    left: -3%;
  }

  .card-exipry-progress,
  .cancelProgress {
    position: relative;
    margin-top: 26px;
    width: 80%;
    left: 4%;
  }

  .pdt25 {
    padding: 10px 23px !important;
  }

  .padt38 {
    padding: 12px 23px;
  }

  .pdl15 {
    padding-left: 0px;
  }

  .padding15 {
    padding: 0px 13px;
  }

  .pending-card {
    position: relative;

    .svgicon {
      position: relative;
      left: -8%;
    }
  }

  .ptd10 {
    padding-top: 10px !important;
  }

  .ptd16 {
    padding-top: 16px !important;
  }

  .dashboard-header {
    padding: 26px 30px;
  }

  .AttachIconBtn {
    left: 23%;
  }

  .DataTableSub {
    overflow: unset !important;
  }

  .fontRoboto {
    font-size: 20px;
    white-space: nowrap !important;
  }

  .ModalHeight {
    height: auto;
  }

  .span-names-agreement {
    left: 7%;
  }

  .closemodal1 {
    left: 74%;
  }

  .closemodal2 {
    left: 90%;
  }

  .HoverSync:hover {
    border: 0.5px solid rgba(54, 47, 47, 0.5);
  }

  .card-expiry,
  .card-cancelled {
    position: relative;
    top: 0%;
  }

  .card-expiry,
  .card-cancelled {
    height: 147px;
    border: 1px solid #f1f5f7;

    .mobileCancel {
      position: relative !important;
      top: 11% !important;
      left: 4%;
    }

    .mobileCancel1 {
      position: relative !important;
      top: 36% !important;
    }
  }

  .costRevenuPadding {
    right: 2%;
    padding-right: 5px;
  }

  .mobileSync {
    width: 40px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    text-overflow: ellipsis;
  }

  #printagreement {
    // margin: 1rem;
    // margin-left: 13%;
    margin: 0px 68px;

    .paddingTable {
      width: 100%;
    }
  }

  .mobileCardRoute {
    overflow: auto;
  }

  .roadMobileImg {
    min-width: max-content;
  }

  .MobileArrived {
    position: absolute;
    width: 25%;
    left: 100px;
    padding-bottom: 110px;
  }

  .MobilePlannedCard {
    width: 27%;
    left: -23px;
  }

  .MobilbeQualityCheckCard {
    width: 26%;

    left: 300px;
    position: absolute;
  }

  .MobileShipmentCard {
    width: 26%;
    left: 550px;
    position: absolute;
  }

  .MobileArrivedAtVia {
    width: 26%;
    left: 817px;
    position: absolute;
  }

  .MobileCompletedCard {
    width: 25%;
    left: 1100px;
    position: absolute;
    padding-bottom: 129px;
  }

  .MobileIntransist {
    position: absolute;
    left: 681px;
    width: 25%;
    padding-bottom: 109px;
  }

  .MobileLoading {
    width: 25%;
    position: absolute;
    padding-bottom: 113px;
    left: 462px;
  }

  .shipCardBox {
    left: 0%;
    display: inline-flex;

    .mobileShipPad {
      padding-left: 0px !important;
    }
  }

  .table-responsive-xl {
    overflow-x: unset;
    -webkit-overflow-scrolling: touch;
  }

  .shipModel .textAttachedColor {
    margin-left: 0px;
    color: white;
  }

  // .closemodal {
  //   left: 83%;
  //   top: 16%;
  // }
  .mobileExpenseColumn {
    left: 72%;
    display: flex;

    .btnExpense {
      margin-top: 20px !important;
    }
  }

  .invoice .mdl .modal-content {
    height: auto !important;
  }

  .PaddingCol {
    padding: 0px 23px;
  }

  #printagreement {
    margin-left: 5%;
  }

  .desktopDisplayShipment {
    display: none;
  }

  .desktopDisplay {
    display: none;
  }

  .desktopDisplayaddIndent {
    display: none;
  }

  .mobileDisplayCards {
    display: block;
  }

  .mobileDisplayShpment {
    display: flex;
  }

  .mobileDisplay {
    display: block;
  }

  // .textALignUser{
  //   padding-left: 28%;
  //   text-align: start;
  // }
  .textALign {
    padding-left: 28%;
    text-align: start;
  }

  .mobileRoute {
    padding-left: 104px;
  }

  .desktop-reset {
    display: none;
  }

  .mobile-reset {
    display: inline;
    width: 45px;
    height: 26px;

    .mobile-rest-img {
      height: 19px;
      margin-top: -5px;
    }
  }

  .textALign {
    padding-left: 0px;
    text-align: start;
  }

  .mobileRoute {
    padding-left: 0px;
  }

  .makeStyles-label-2 {
    font-size: 8px !important;
  }

  // .laneLotButtons{
  //   flex-wrap: wrap;
  // }
  .trackingAbosulte table {
    font-size: 10px;
  }

  .sourceTransist {
    font-size: 10px;
  }

  .trackingAbosulte {
    width: 90%;
  }

  .detailsTripMaps {
    right: 0%;
    width: 38%;
    top: 19%;
  }

  .mapsLeftArrowImg {
    left: 90%;
  }

  .BillDetailsFont {
    font-size: 10px;
  }

  .agreement-filters {
    .agreementDesktopRefresh {
      display: none;
    }

    .agreementMobileRefresh {
      display: flex;
    }

    // .flex-row-end{
    //   margin-left: -22px;
    // }
  }

  .driverMobileCol {
    .flex-row {
      margin-left: -8px;
    }
  }

  .quickStylesBar {
    .desktopLanelot {
      display: none;
    }

    .mobileLanelot {
      display: block;

      .roboto_family {
        font-size: 8px !important;
      }

      .laneLotButtons {
        padding-left: 0px;
        margin-bottom: 0px;

        li {
          list-style-type: none;
          margin: 0px 8px;

          &:first-child {
            margin-left: 0px;
          }
        }
      }
    }
  }

  .vehicleStyle {
    .justify-content-start {
      margin-left: -8px;
    }
  }

  .desktopReportAnalysis {
    display: none;
  }

  .mobileReportAnalysis {
    display: flex;
  }

  .reportQuickStyleBar {
    .mobileCalender {
      margin-left: 20px !important;
    }

    .reportflex {
      padding-left: 10px;
    }

    .reportPadding {
      padding: 0px;
    }

    .reportPaddingCol {
      padding: 0px;
      padding-right: 10px;
      padding-left: 40px;
    }

    .desktopResetButton {
      display: none;
    }

    .mobileResetButton {
      display: flex;
      // padding-left: 10px;
    }
  }

  .paginationRow {
    padding: unset;

    .spanPagenationentries {
      font-size: 10px;
    }

    .span-col-pagenation {
      padding: 0px 0px !important;

      .desktop-pagination {
        display: none;
      }

      .mobile-pagination {
        display: flex;
      }
    }
  }

  // .cardIndexNeo{
  //   width:100%;
  //   min-width: auto;
  //   overflow-x: scroll;
  //   display: contents;
  //   flex-wrap: nowrap;
  // }
  .tripMobileHeader {
    padding-right: 40px;
  }

  .controlTowerMobileHeader {
    padding-left: 0px !important;
    padding-right: 30px;
  }

  .indentMobileHeader {
    padding-right: 20px;
  }

  .desktopTrans {
    display: none;
  }

  .textEcllipse {
    display: block;
    position: absolute;
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .navbar-header-new-top {
    padding-bottom: 45px !important;
  }

  .navbar-header-new {
    .mobileAddIndent {
      display: contents;
    }

    .desktopAddIndent {
      display: none;
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    // box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .gridContainer {
    .indentFeildPd {
      padding-right: 0px !important;
    }

    .mobileIndentImg {
      display: none;
    }

    .select2-selection__control {
      width: 90%;
    }

    .formFieldHeaderStyle {
      font-size: 12px;
    }

    .dateTimeInputParent {
      width: 90%;
    }
  }

  .grid-card-container,
  .operationsGridCard {
    padding: 0px 12px !important;
  }

  .barchartPt {
    margin-top: 10px !important;
  }

  .transDashPD {
    padding: 0px 25px !important;
    padding-left: 28px !important;
  }

  .transportCard {
    width: 90%;
    height: auto;
    min-height: 100%;
    padding-bottom: 10px;
  }

  .apexChart-card {
    height: 550px;
    padding-bottom: 20px;
  }

  .shipment-card-parent {
    height: auto;
    padding-bottom: 20px;
    margin-top: 5px;

    .MuiGrid-spacing-xs-2>.MuiGrid-item {
      padding: 0px !important;
    }

    .MuiGrid-root>.MuiGrid-item {
      padding-left: 0px !important;
      padding-top: 0px !important;
    }

    .shortage-shipment-card,
    .shipment-card {
      width: 90%;
      height: 118px;
      margin-bottom: 10px;

      .shipment-card-value {
        font-size: 20px !important;
      }
    }

    .shipment-card {
      margin-left: 15px;
    }

    .shortage-shipment-card,
    .shipment-card {
      .MobileShipment {
        position: relative;
        left: 8%;
        top: 7%;
      }

      .MobileShipment1 {
        // position: relative;
        // top: 31%;
        width: 90%;
        position: relative;
        left: 6%;
        top: 20%;
      }
    }

    .shortage-shipment-card {

      // padding-left: 20px;
      // margin-left: 10px;
      .shipcard-percentage {
        margin-top: 2px !important;
      }
    }
  }

  .operational-Tat-parent {
    height: auto;

    .operational-label-div {
      display: none !important;
    }

    .OperationalTatLabelsMobileDiv {
      padding-left: 44px;
      display: flex !important;
      padding-bottom: 10px;
      width: 100%;
      white-space: break-space;
      justify-content: center;

      .OperationalTatLi {
        position: relative;
        list-style-type: none;
        padding: 0px 10px;
        padding-right: 33px;
        display: inline-block;
        justify-content: space-between;

        .mobileSpanOperationalTat {
          width: 67%;
          white-space: initial !important;
        }

        .span-operationalTat-cirlce1 {
          left: -15px;
          right: auto;
        }
      }
    }

    .operational-Tat-card {
      margin-left: 15px;
    }

    .mobile-operational-tat-labels {
      width: 100%;
      min-width: 100%;
      display: flex;
      white-space: initial;
      flex-grow: 1;
      flex-direction: row !important;
      flex-shrink: initial !important;
    }

    .span-operationalTat-cirlce1 {
      top: 5px;
    }
  }

  .indent-details-parent {
    height: auto;
  }

  .onTimeDelivery {
    height: auto;

    .onTimeDeliveryDetails {
      height: 150px;
      padding-top: 10px;
    }

    .card-ontime-dountchart {
      height: 150px;
    }
  }

  .login-page-descktop-div {
    display: none;
  }

  .login-page-mobile-div {
    display: flex;

    .mobile-login-page-img-trans,
    .mobile-login-page-img-copy {
      padding-top: 15px;
      width: 100%;
      justify-content: center;
      display: flex;
    }

    .mobile-login-img-logo-div {
      width: 100%;
      justify-content: center;
      display: flex;
    }

    .mobile-login-page-row {
      position: absolute;
      top: 7%;
      justify-content: center;
      width: 100%;
      align-items: center;

      .mobile-login-col {
        padding: 0px 40px;
        padding-right: 20px;
      }
    }

    .mobile-login-page-img {
      position: relative;
    }

    .mobile-login-div {
      position: relative;
      width: 100%;
      min-width: 100%;
      height: auto;
      background-color: #ffffff;
      display: flex;
      padding: 10px 15px;
    }
  }

  .pending-card {
    display: flex;
    justify-content: flex-end;
    padding-right: 22%;
  }

  .mobile-toggle {
    display: flex;
    padding-top: 2px;
    cursor: pointer;
  }

  #mySidebar {
    display: none;
  }

  #my-sidebar-mobile {
    display: block;
    width: 300px;
  }

  #contentCard {
    margin-left: 0px !important;
    // padding: 0px 25px;
    margin-right: 0px;
  }

  .dashboard-header-div {
    .grid-align {
      display: none;
    }
  }

  .dashboard-header-div {
    .grid-align-mobile {
      display: flex;

      .mobile-dashboard-header-buttons {
        padding-left: 0px;
        margin-bottom: 0px;

        li {
          list-style-type: none;
          margin: 0px 8px;

          &:first-child {
            margin-left: 0px;
          }
        }
      }

      .react-select__control {
        height: 32px;
      }

      .react-select__indicators {
        height: 30px;
      }
    }
  }

  .dashboard-icon {
    padding-left: 0px;
  }

  // .dashboard-name {
  //   font-size: 12px !important;
  // }
  .dashboard-cards-grid-div {

    // .MuiGrid-item{
    //   padding: 5px;
    // }
    .dashboard-card-spacing {
      padding: 0px 5px !important;
    }

    .dashboard-cards-grid {
      padding-left: 10px !important;
      padding-right: 12px !important;
    }
  }

  .dashContainerDiv {
    width: auto !important;
    margin: auto !important;
  }

  .pendingPdCss {
    padding: 10px 12px !important;
  }

  .mobile-grid-card-container {
    padding: 0px 18px !important;
  }

  .invoiceDashboardDiv {
    .freight-costPd {
      padding: 0px 19px !important;
    }

    .dashboard-header-invoice-div {
      padding: 0px 12px;

      .desktop-acceptance {
        display: none;
      }

      // .freight-cost-span-names {
      //   font-size: 12px;
      //   white-space: initial;
      // }
      .freight-cost-span-values {
        font-size: 20px;
      }

      .Pending-card {
        width: 100%;
        height: 226px;
      }

      .pending-pd {
        padding-bottom: 5px;
        padding-top: 5px !important;
      }

      .mobile-acceptance {
        display: flex;
        padding-left: 30px;
        padding-top: 7px;
        justify-content: space-between;
      }
    }

    // .freight-cost-name-apx {
    //   font-size: 12px !important;
    // }
    .mobile-freight {
      display: flex;
    }

    .desktop-freight {
      display: none;
    }
  }

  // .transpoter-labels {
  //   width: 90px;
  //   font-size: 10px !important;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }
  // .transpoter-labels:hover,
  // .transpoter-labels:focus {
  //   overflow: visible !important;
  //   white-space: initial;
  //   cursor: pointer;
  //   width: 100% !important;
  //   transform-style: initial;
  // }
  .laneLotButtonsMobilePd {
    width: 100%;
    padding-right: 0px;
    margin-right: -71px;
  }

  .quickStylesBar .MuiPickerStaticWrapper-root,
  .trip-quick-filters .MuiPickerStaticWrapper-root,
  .indent-quick-filters .MuiPickerStaticWrapper-root {
    left: 35%;
  }

  .gridContainer .rdtOpen .rdtPicker {
    display: block;
    top: -495%;
    left: 37%;
  }

  .shipment-hr-line,
  .trip-status-hr-line,
  .operationalTat-hr-line,
  .indent-hr-line,
  .carbonFootPrint-hr-line,
  .costToRevenueRatio-hr-line,
  .freightCost-hr-line {
    width: 93%;
  }

  .tripModel {
    .qualityImageBox {
      width: 95%;
    }
  }
}

.fixed-element {
  position: absolute;
  top: -37px;
  left: 242px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
}

////new tripDetaailUI
.menu-element {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1px;
  gap: 25px;
}

.menu-child-element {
  margin: 4px 4px 4px 4px;
  padding: 4px 4px 4px 4px;
}

.menu-span {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: rgb(55, 133, 216);
  cursor: pointer;
}

.vertical-line {
  border-left: 2px dashed #ccc;
  height: 90px;
  margin-left: 7px;
}

.activeMenu {
  border-top: 2px solid rgb(55, 133, 216);
  width: 85%;
}

.sorcDestVal {
  margin: 13px 0px 0px 25px;
}

.sorcVal {
  margin: 15px 0px 0px 18px;
}

.online {
  div.online-indicator {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 15px;
    background-color: #4aa362;
    border-radius: 50%;
    position: relative;
  }

  span.blink {
    display: block;
    width: 14px;
    height: 14px;
    background-color: #32a852;
    opacity: 0.7;
    border-radius: 50%;
    animation: blink 1s linear infinite;
  }

  p.online-text {
    display: inline;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: larger;
    text-shadow: 0px 3px 6px rgba(150, 150, 150, 0.2);
    position: relative;
    cursor: pointer;
  }

  @keyframes blink {
    100% {
      transform: scale(3, 3);
      opacity: 0;
    }
  }
}

.tripTrackParent-element {
  display: flex;
  flex-direction: row;

  .track-element {
    border: 1px solid #00a36c;
    width: 50px;
    height: 50px;
    margin: 2px;
    background-color: #00a36c;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .compleTrack-element {
    border: 1px solid lightgray;
    width: 50px;
    height: 50px;
    margin: 2px;
    background-color: rgb(248, 243, 243);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .track-element1 {
    height: 165px;
    margin-left: 23px;
    border-left: 3px solid #00a36c;
    border-bottom: 3px solid #00a36c;
    width: 40px;
  }

  .track-elementP {
    height: 134px;
    margin-left: 23px;
    border-left: 3px solid #00a36c;
    border-bottom: 3px solid #00a36c;
    width: 40px;
  }

  .track-element2 {
    height: 40px;
    margin-left: 23px;
    border-left: 3px solid #00a36c;
    width: 40px;
  }
}

.cardStyle {
  box-shadow: 0px 0px 6px rgba(77, 73, 73, 0.25) !important;
  border-radius: 4px !important;
}

////new tripDetaailUI
.bottom-fixed-element {
  position: absolute;
  top: 8px;
  left: 365px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  gap: 12px;
}

.child-element {
  margin: 10px;
  padding: 10px;
}

@media (max-width: 577px) {
  .tripModel {
    .qualityImageBox {
      width: 97%;
    }
  }

  .TripCancelModal,
  .CanelIndentModal {
    min-width: 450px;
  }

  .ReportIncidentModal {
    min-width: 450px;
  }

  .TranspoterModal {
    min-width: 450px;
  }

  .RejectIndentModal {
    min-width: 450px;
  }

  .imageIconS {
    padding-left: 23%;
  }
}

@media (max-width: 480px) {
  .PendingContent {
    font-size: 8px !important;
  }

  .imageIconS {
    padding-left: 22%;
  }

  .spanPagePadding {
    font-size: 10px;
  }

  .OperationalTatLabelsMobileDiv {
    .mobileSpanOperationalTat {
      width: 67% !important;
    }
  }

  .pending-card {
    padding-right: 11%;
  }

  .AttachIconBtn {
    left: 17%;
  }

  .closemodal2 {
    left: 90%;
  }

  .fontRoboto {
    font-size: 15px;
    white-space: nowrap !important;
  }

  .closemodal1 {
    left: 71%;
  }

  .invoiceDashboardDiv {
    .dashboard-header-invoice-div {
      .mobile-acceptance {
        display: flex;

        .imgInvoiceCol1 {
          left: 14%;
        }

        .InvoiceCol2 {
          left: 9%;
        }

        .InvoiceCol3 {
          left: -23%;
        }
      }

      .desktop-acceptance {
        display: flex;
      }

      .Pending-card {
        width: 100%;
        height: 226px !important;
      }
    }

    .mobile-freight {
      display: none;
    }

    .desktop-freight {
      display: flex;
    }
  }

  .mobileLanelot {
    .makeStyles-label-2 {
      font-size: 8px !important;
    }

    .mobileLaneLotButtons {
      width: 60%;
    }
  }

  .navbar-header-new-top {
    .span-font-roboto {
      font-size: 9px;
      white-space: nowrap;
    }

    // .profile-name{
    //   font-size: 10px;
    // }
  }

  .costRevenuPadding {
    right: 3%;
    padding-right: 5px;
  }

  .quickStylesBar .MuiPickerStaticWrapper-root,
  .trip-quick-filters .MuiPickerStaticWrapper-root,
  .indent-quick-filters .MuiPickerStaticWrapper-root {
    left: 13%;
  }

  .MobileflesParent {
    margin-left: -36px;
    position: relative;
    width: 95%;
  }

  .laneLotButtonsMobilePd {
    width: 67%;
    padding-left: 3%;
  }

  .laneLotButtonsMobilePd1 {
    width: 84%;
    padding-left: 14%;
  }

  .MobileProfileParent {
    width: 150px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .profile-name {
      font-size: 10px !important;
    }
  }

  .reportQuickStyleBar {
    .mobileCalender {
      margin-left: 10px !important;
    }
  }

  .apexcharts-xaxis text {
    font-size: 9px !important;
  }

  .shortage-shipment-card,
  .shipment-card {
    width: 85% !important;
    height: 178px;

    .shipment-card-value {
      font-size: 20px !important;
    }
  }

  .trip-card-div {
    .tripstatus-names {
      font-size: 10px !important;
    }
  }

  .dashboard-card-div {
    .dashboard-number-col {
      padding-top: 20px;
    }

    .dashboard-icon-bottom {
      padding-top: 20px !important;
    }

    .dashboard-number {
      padding-top: 10px !important;
      font-size: 20px !important;
    }

    .dashboard-name {
      font-size: 12px !important;
    }
  }

  .mobile-dashboard-header-buttons {
    li {
      margin: 0px 5px !important;

      Button {
        font-size: 12px;
        padding: 0px 5px;
      }

      &:first-child {
        margin-left: 0px !important;
      }
    }
  }

  .span-operational {
    width: 50px;
  }
}

@media (max-width: 437px) {

  .TripCancelModal,
  .CanelIndentModal {
    min-width: 350px;
  }

  .ReportIncidentModal {
    min-width: 350px;
  }

  .RejectIndentModal {
    min-width: 350px;
  }

  .spanPagePadding {
    font-size: 10px;
  }

  .raiseLine {
    line-height: 2;
  }
}

@media screen and (max-width: 612px) {
  .cardImgRes {
    width: 28% !important;
    height: auto !important;
  }
}

@media (min-width: 380px) and (max-width: 420px) {
  .closemodal {
    left: 89%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .closemodal {
    left: 89%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .closemodal {
    left: 89%;
  }

  .cardImgRes {
    width: 128% !important;
    height: auto !important;
  }
}

@media (max-width: 380px) {
  .AttachIconBtn {
    left: 13%;
  }

  .closemodal {
    left: 89%;
  }

  .MobileProfileParent {
    width: 100px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .profile-name {
      font-size: 10px !important;
    }
  }

  .trip-card-div {
    height: auto;
    padding-bottom: 10px !important;
  }

  .mobile-trip-label-pd {
    padding-left: 21px;
    width: 100%;
    white-space: nowrap;
    // margin-left: 18px !important;
  }

  .apexChart-card {
    .apexcharts-tooltip {
      right: 5% !important;
      left: 5% !important;
    }
  }

  .IndentApexchartParent,
  .TripApexChartParent {
    .apexcharts-tooltip {
      right: 5% !important;
      left: 5% !important;
    }
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .verifyPadding {
    right: 19px;
  }

  .apexChart-card {
    .apexcharts-tooltip {
      right: 5% !important;
      left: 10% !important;
    }
  }

  .IndentApexchartParent,
  .TripApexChartParent {
    .apexcharts-tooltip {
      right: 5% !important;
      left: 10% !important;
    }
  }
}

@media (min-width: 360px) and (max-width: 460px) {
  .mobile-operational-Tat-labels {
    padding-left: 30px;
  }

  .apexChart-card {
    .apexcharts-tooltip {
      right: 5% !important;
      left: 5% !important;
    }
  }

  .IndentApexchartParent,
  .TripApexChartParent {
    .apexcharts-tooltip {
      right: 5% !important;
      left: 5% !important;
    }
  }
}

@media (min-width: 641px) and (max-width: 1000px) {
  .trackingAbosulte {
    width: 40%;
  }

  .mapsLeftArrowImg {
    left: 41%;
  }
}

@media only screen and (min-width: 1280px) {
  .dashLeftInput {
    margin-left: -2% !important;
  }

  .onTimeDeliveryQuickFilter {
    .quickStylesBar {
      width: 95%;
    }
  }
}

@media (min-width: 1200px) and (min-width: 1399px) {
  .MobileShipment {
    .fontRoboto {
      font-size: 15px;
    }
  }

  .card-expiry,
  .card-cancelled {

    .expiryVal,
    .cancelVal {
      padding: 1px;

      .fontRoboto {
        font-size: initial;
      }
    }
  }
}

.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

// date calender range header
.dark__mode {

  // .darkDatepicker-selector {
  .rs-picker-daterange-panel,
  .rs-picker-daterange-panel-show-one-calendar,
  .rs-picker-toggle-textbox,
  .rs-picker-toggle-read-only {
    background-color: #2e2e46 !important;
    color: #b7a9ff !important;
  }

  .rs-picker-daterange-header,
  .rs-calendar-table-cell-content,
  .rs-picker-toggle-value {
    color: #ffffff !important;
  }

  .rs-picker-daterange-header,
  .rs-calendar-header,
  .rs-calendar-header,
  .rs-btn-icon,
  .rs-btn,
  .rs-calendar-table {
    color: white !important;
  }

  .rs-calendar-table-header-row .rs-calendar-table-cell .rs-calendar-table-cell-content {
    color: white !important;
  }

  .rs-picker-toggle {
    background-color: #2e2e46 !important;
  }

  //Rsuite datepicker
  .rs-calendar {
    background-color: #2e2e46 !important;
    color: #b7a9ff !important;
  }

  .rs-picker-toolbar {
    background-color: #2e2e46 !important;
    // color: #b7a9ff !important;
  }

  .rs-picker-toggle-placeholder {
    color: white !important;
  }

  .rs-calendar-month-dropdown-row-wrapper {
    background-color: #2e2e46 !important;
    color: white !important;
  }
}

//darkmode-------------------------------
.pb-3:last-child .DashedLine {
  display: none !important;
}

.tracking_page_subscribe_consent_btn {
  display: inline-flex;
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
  background-color: #0ab38c;
  margin-left: 4px;
}

.HereMaps {
  canvas {
    width: 100% !important;
  }
}

//DatePicker changes
.mobileIndentRow {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-end !important;
}

.dashboardDateRange {
  .rs-picker-toggle-wrapper {
    width: 45% !important;
  }

  .rs-picker-toggle-textbox {
    padding-left: 0px;
    display: none;
  }

  .rs-input-group {
    height: 30px;
  }

  .rs-picker-toggle-wrapper:hover:active {
    border: none !important;
    padding: 0px !important;
  }

  .rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle {
    height: 26px;
  }

  .rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle {
    margin-right: -39px;
    height: 26px;
  }

  .rs-picker-toggle-placeholder {
    position: absolute;
    top: 4px;
    color: #0c0c0c !important;
  }

  .rs-picker-toggle-value {
    padding-top: 4px;
    padding-right: 5px;
  }
}

.CancelOrRejectIcon {
  color: rgb(241, 21, 21);
  font-size: 16px;
}

/*Accordion*/
.accordion {
  width: 100%;
  // max-width: 1000px;
  // margin: 2rem auto;
}

.accordion-item {
  background-color: #fff;
  color: #111;
  margin: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
}

.accordion-item-header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.accordionIcon {
  float: right;
  font-size: 0rem;
  position: absolute;
  right: 0rem;
  background-color: aliceblue;
}

.accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 1px solid;
  background-color: #f1f5f7;
  border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
}

.divWrap {
  text-overflow: ellipsis;
  width: 250px !important;
  white-space: nowrap;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
}

.divWrap1 {
  width: 250px !important;
  white-space: wrap;
}

.commentH {
  font-size: 15px;
  color: #ffff;
  font-weight: 900;
}

.rs-anim-fade.rs-anim-in {
  z-index: 10000;
  // top: 10% !important;
  // left: 70% !important;
  position: absolute;
}

.OptChild1 .ListingPages .WaveLoaderdiv {
  top: 30% !important;
  right: 672px !important;
  z-index: 999 !important;
}

@media (max-width: 1279px) {
  .GridNone {
    display: none;
  }
}

// @media (max-width: 768px) {
.input-borderDIv {
  .marg-10 {
    margin-top: 15px;
  }
}

// }
.PendingContent {
  font-size: 11px;
  position: relative;
  top: 3px;
}

.WaringModal {
  .modal-content {
    min-width: 400px;
    padding: 10px;
  }

  .modal-header {
    border-bottom: none !important;
  }

  .buttons1,
  .buttons2 {
    width: 100px;
    padding: 5px;
    margin-bottom: 15px;
  }

  .modal-title {
    h2 {
      color: #bd8309 !important;
    }
  }

  .buttons2 {
    background-color: rgb(6, 6, 33) !important;
  }

  .buttons1 {
    background-color: rgb(137, 14, 14) !important;
  }
}

.addInvoice {
  width: 100vw;
}

.InvoiceSubmitBtn {
  background-color: #0c0c2b !important;
}

.addInvoice {
  .modal-fluid {
    min-width: 100vw !important;
  }

  .rs-anim-fade.rs-anim-in {
    z-index: 10000;
    top: 200px !important;
  }
}

@media (max-width: 992px) {
  .invoiceEditParent {
    overflow: auto;

    .Card1 {
      width: 150%;
    }
  }
}

// @media (min-width: 1200px) and (max-width: 1400px) {
//   .addInvoice {
//     .modal-content {
//       left: -13%;
//     }
//   }
// }
// @media (min-width: 1700px) and (max-width: 2000px) {
//   .addInvoice {
//     .modal-content {
//       left: -45%;
//     }
//   }
// }
.logbookButton {
  background-color: #05284e;
  color: #fff;
  // float: right;
  cursor: pointer;
  height: 30px;
}

.logbookButtonDownload {
  background-color: #05284e;
  color: #fff;
  cursor: pointer;
}

.LogBook {
  display: flex;
  justify-content: flex-end;

  // padding-right: 10%;
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #fff !important;
    background-color: #000830;
    border-color: #74788d;
    font-weight: 700;
    height: 30px;
  }

  .dateickerGrid {
    // border: 0.5px #7f7fc2;
    width: fit-content;
    height: 30px;
    margin-top: 6px;

    .input-group {
      border: 1px solid #d2c3c3 !important;
      border-radius: 5px;
      padding: 2px;
    }
  }
}

.QuickDiv {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 641px) and (max-width: 1024px) {
  .LogBook {
    padding-right: 10%;
  }

  .QuickDiv {
    padding-right: 10%;
  }
}

@media (max-width: 640px) {

  .LogBook,
  .QuickDiv {
    display: block;
  }
}

.OptParentDiv {
  display: flex;
  width: 100%;

  .OptChildSub {
    width: 37% !important;
    margin-right: 10px;

    .Heading {
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 700;
      padding: 30px;
      color: #05284e;
    }
  }

  .OptChild {
    width: 100%;
    height: 780px;
    overflow-y: auto;
    margin-top: 15px;

    .OptCard {
      width: 100%;
      height: 70px;
      margin-bottom: 10px !important;
      display: flex;
      padding-top: 25px;
      padding-left: 20px;

      span {
        color: rgb(5, 40, 78);
      }

      .h {
        text-align: start;
        align-items: center;
        padding-top: 25px;
        font-size: 14px;
        font-family: Roboto;
        padding-left: 20px;
        font-weight: 450;
        color: rgb(5, 40, 78);
      }
    }
  }

  .OptChild1 {
    width: 70%;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .OptParentDiv {
    .OptChildSub {
      width: 20%;
    }

    .OptChild1 {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .OptParentDiv {
    .OptChildSub {
      width: 30%;
    }

    .OptChild1 {
      width: 40%;
    }
  }
}

.rejectBtn {
  background-color: rgb(72, 8, 8);
  color: #fff;
  margin-right: 20px;
  padding: 10px 50px;
}

.totalAmount {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

@media (max-width:1300px) {
  .totalAmount {
    width: 90%;
  }
}