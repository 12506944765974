.apexChart-card {
  background: #f1f5f7;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: 550px;
}

.barChart-card {
  background: #fbfbfb;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: 550px;
}

.barCardStyle {
  border-style: "solid";
  border-width : "thin";
  border-color: "white";
  border-radius : "5px";
  background-color: "#F1F5F7"
}

.transportCard {
  overflow-y: scroll;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100%;
  max-height: fit-content !important;
  min-height: fit-content !important;
  background: #fbfbfb !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  left:10px !important;
  /* bottom: -100px !important; */
}

.gridStyle {
  position: absolute;
  width: 100%;
  height: 104px;
  left: 14px;
  float: left;
  left: 14px;
  background: #fbfbfb;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.round {
  position: absolute;
  width: 12px;
  height: 12px;
  float: right;
  border-radius: 20px;
}
.transporterTextStyle {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  color: #0c0c0c;
  font-weight: 400;
  margin-left: 19px;
}

.roundDot {
  position: absolute;
  border-radius: 20px;
  margin: 2px;
  width: 4px;
  height: 4px;
  background-color: #2800fc;
  border: 2px solid #6548ff;
}

.barChartHeader {
  position: absolute;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #05284e;
}

.lineStraight {
  /* border: 0px solid #e5e5e5; */
  border-width: thin;
  border-top: 1px solid #e5e5e5;
  width: 98%;
  margin-bottom: 11px;
}
#chart-wrap {
  max-width: 650px;
  position: relative;
  margin: 35px auto;
}

#chart {
  padding-bottom: 30px;
  max-width: 650px;
}

.legend {
  max-width: 650px;
  left: 80px;
  bottom: 20px;
  position: absolute;
  text-align: center;
  margin: 0 auto;
}

.transporter-dashboard-btn-transpoter {
  font-family: Roboto !important;
  font-style: normal;
  background-color: #0ab38c !important;
  color: #05284e !important;
}
.transporter-dashboard-btn-operations {
  font-family: Roboto !important;
  font-style: normal;
  background-color: #f1f5f7 !important;
  color: #05284e !important;
}
